import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TextArea from './InputTypes/TextArea';
import SelectBox from './InputTypes/SelectBox';
import NumberBox from './InputTypes/NumberBox';
import CheckBox from './InputTypes/CheckBox';
import DateBox from './InputTypes/DateBox';
import Lookup from './InputTypes/Lookup';
import PasswordBox from './InputTypes/PasswordBox';
import EmailBox from './InputTypes/EmailBox';
import PhoneBox from './InputTypes/PhoneBox';
import URLBox from './InputTypes/URLBox';
import RadioButtonGroup from './InputTypes/RadioButtonGroup';
import TimeBox from './InputTypes/TimeBox';
import FileUpload from './InputTypes/FileUpload';
import RangeSlider from './InputTypes/RangeSlider';
import ColorPicker from './InputTypes/ColorPicker';
import ToggleSwitch from './InputTypes/ToggleSwitch';
import MarkdownEditor from './InputTypes/MarkdownEditor';
import RichTextEditor from './InputTypes/RichTextEditor';
import SignatureBox from './InputTypes/SignatureBox';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import DescriptionInput from './InputTypes/DescriptionInput'; 
import { LuPenLine } from "react-icons/lu";
import './DetailForm.css';

// Component and data type mappings
const componentMapping = {
  select: SelectBox,
  textarea: TextArea,
  number: NumberBox,
  checkbox: CheckBox,
  date: DateBox,
  text: TextArea,
  string: TextArea,
  Icon: Lookup,
  password: PasswordBox,
  email: EmailBox,
  phone: PhoneBox,
  url: URLBox,
  radio: RadioButtonGroup,
  time: TimeBox,
  file: FileUpload,
  range: RangeSlider,
  color: ColorPicker,
  toggle: ToggleSwitch,
  markdown: MarkdownEditor,
  richtext: RichTextEditor,
  signature: SignatureBox,
};

const typeColors = {
  text: '#03a9f4',
  string: 'blue',
  number: 'green',
  boolean: 'purple',
  checkbox: 'purple',
  date: 'orange',
  select: 'teal',
  password: '#f44336',
  email: '#9c27b0',
  phone: '#4caf50',
  url: '#ff5722',
  radio: '#e91e63',
  time: '#607d8b',
  file: '#009688',
  range: '#795548',
  color: '#ffc107',
  toggle: '#ff9800',
  markdown: '#673ab7',
  richtext: '#3f51b5',
  signature: '#8bc34a',
  DescriptionInput: '#009688',
};

const DetailForm = ({ data, id, tableName, columns }) => {
  const location = useLocation();
  const { formData: initialFormData } = location.state || {};
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState(initialFormData || data || {});
  const [saving, setSaving] = useState(false); // For showing progress
  const [alertMessage, setAlertMessage] = useState(null); // Alert for success/failure
  const otherFields = Object.entries(formData).filter(([key]) => key !== 'description');
  const descriptionField = Object.entries(formData).find(([key]) => key === 'description');

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFormData(data);
    }
  }, [data]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const onSaveClick = async () => {
    setSaving(true); // Start showing progress
    setAlertMessage(null); // Clear previous messages
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }
  
      // Optimistically update the UI with the current formData
      const updatedData = { ...formData }; // Clone formData (the payload)
  
      const response = await fetch(`https://med-b.ealamgroup.pk/srecord/${tableName}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Network response was not ok. Status: ${response.status}`);
      }
  
      // Set the formData to the data we sent, ensuring the UI reflects the updated data
      setFormData(updatedData);
  
      // Exit editing mode immediately after a successful save
      setEditing(false);
      setAlertMessage({ type: 'success', message: 'Data saved successfully!' });
  
    } catch (error) {
      setAlertMessage({ type: 'error', message: `Failed to save: ${error.message}` });
    } finally {
      setSaving(false); // Stop showing progress
    }
  };

  const onCancelClick = () => {
    setEditing(false);
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const getTypeColor = (value) => {
    const type = typeof value;
    return typeColors[type] || 'black'; // Fallback to black for unknown types
  };

  const renderField = (key, value) => {
    if (!editing) {
        return value ? <span style={{ color: ' var(--text-color1)' }}>{value}</span> : <span style={{ color: ' var(--text-color1)' }}>___</span>;
    }

    const column = columns.find((col) => col.name === key);
    const fieldType = column ? column.type : 'text'; // Default to 'text' if type not found
    const Component = componentMapping[fieldType] || TextArea; // Fallback to TextArea for unknown types

    const componentProps = {
        value,
        onChanged: (newValue) => handleChange(key, newValue),  // Use onChanged instead of onValueChanged
        readOnly: !editing,
    };

    // Special handling for Lookup
    if (fieldType === 'Icon' && column.options) {
        componentProps.options = column.options; // Pass options if available
    }
    // Use DescriptionInput for the description field
    if (key === 'description') {
      return <DescriptionInput {...componentProps} />;
    }
    return <Component {...componentProps} />;
};

  const renderLabel = (key, value) => {
    return (
        <div className="detail-label" style={{ color: getTypeColor(value),fontSize:'12px' }}>
        {key.charAt(0).toUpperCase() + key.slice(1)}:
      </div>
    );
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="caption"> Detail | Record ID : {id} </span>
        <div className="buttons-edit">
          {!editing && (
                        <button onClick={handleEditClick} className="edit-btn">
                        <LuPenLine style={{ fontSize: "20px" }} />
                        Edit{" "}
                      </button>
          )}
          {editing && (
            <>
              <button onClick={onSaveClick} className="edit-btn">Save</button>
              <button
                onClick={onCancelClick}
                className="close-btn"
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
      <div className="grid-container">
      {otherFields.map(([key, value]) => {
          if (key === 'id') return null; // Skip rendering the 'id' field
          return (
            <div className="form-group" key={key}>
              {renderLabel(key, value)}
              {renderField(key, value)}
            </div>
          );
        })}

        {/* Render Description Field at the end */}
        {descriptionField && (
          <div className="form-group" key="description">
            {renderLabel("description", descriptionField[1])}
            {renderField("description", descriptionField[1])}  
          </div>
        )}
      </div>

      {saving && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}

      {alertMessage && (
        <Snackbar open autoHideDuration={4000} onClose={() => setAlertMessage(null)}>
          <Alert severity={alertMessage.type} onClose={() => setAlertMessage(null)}>
            {alertMessage.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default DetailForm;
