import React from "react";
import { IconButton, Button } from "@mui/material";
import { TbArrowLeftToArc, TbArrowRightToArc } from "react-icons/tb";
import { GoChevronLeft,GoChevronRight } from "react-icons/go";


const Pagination = ({
  rowsPerPage,
  handleRowsPerPageChange,
  editMode,
  saveEdits,
  setEditMode,
  currentPage,
  totalPages,
  filteredDataLength,
  setCurrentPage,
}) => {
  return (
    <div className="pagination">
      <div className="select-container">
        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
          {[10, 15, 20].map((size) => (
            <option key={size} value={size}>
              {size} rows
            </option>
          ))}
        </select>
      </div>
      {editMode && (
        <div style={{ marginTop: "1rem" }}>
          <Button variant="contained" color="primary" onClick={saveEdits}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setEditMode(false)}
            style={{ marginLeft: "1rem" }}
          >
            Cancel
          </Button>
        </div>
      )}
      <div className="pg-2-container">
        <div className="status">
          Page {currentPage} of {totalPages} ( {filteredDataLength} items)
        </div>
        <IconButton
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="pgn-btn"
        >
          <GoChevronLeft  style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="pgn-btn"
        >
          <GoChevronRight style={{ fontSize: "20px" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default Pagination;
