import React, { useState, useEffect } from 'react';
import './Input.css';

const NumberBox = ({ label, value, onChanged }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (value) {
      setIsFocused(true); // Keep the label floated if there is a value
    }
  }, [value]);

  const handleChange = (event) => {
    // Ensure the value is a valid number or string
    const newValue = event.target.value;
    if (!isNaN(newValue)) {
      onChanged(newValue); // Update the state with the numeric value
    } else {
      onChanged(''); // Handle invalid input if necessary
    }
  };

  return (
    <div className="textarea-container">
      <input
        type="number"
        className={`Add-Input ${isFocused ? 'focused' : ''}`}
        value={value || ''} // Ensure that the value is always a string or number, not an object
        onChange={handleChange} // Ensure proper onChange handling
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label className={`Input-label ${isFocused || value ? 'filled' : ''}`}>
        {label}
      </label>
    </div>
  );
};

export default NumberBox;
