import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./Header"; 
import Sidebar from "./Sidebar"; 
import Home from "../pages/Home/Home"; 
import List from "../pages/List/List"; 
import Detail from "../pages/List_detail/List_detail"; 
import Profile from "../pages/Profile/Profile";
import Loading from "./Loading2";

const MainLayout = ({ toggleSidebar, isSidebarOpen, onThemeChange, toggleDarkMode, isDarkMode, setIsAuthenticated }) => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [isUIReady, setIsUIReady] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [homeData, setHomeData] = useState(null);
  const [listData, setListData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [profileData, setProfileData] = useState(null);

  const title = document.querySelector('.header-title')?.innerText || 'Loading';

  const loadingDuration = Math.max(title.length * 150, 3500);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    
    setTimeout(() => {
      setIsUIReady(true);
    }, 500);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleRefresh = async () => {
    if (!isUIReady) return;

    setIsLoading(true);

    setHomeData(null);
    setListData(null);
    setDetailData(null);
    setProfileData(null);


    setTimeout(() => {
      setHomeData({});
      setListData({});
      setDetailData({});
      setProfileData({});

      setIsLoading(false);
    }, loadingDuration);
  };

  return (
    <div className="main-container">
      <Header
        toggleSidebar={toggleSidebar}
        onThemeChange={onThemeChange}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        setIsAuthenticated={setIsAuthenticated}
        onRefresh={handleRefresh}
      />

      {/* Show content as soon as UI is ready */}
      {isUIReady ? (
        <>
          {/* Show loading spinner while data is being fetched */}
          {isLoading ? (
            <Loading duration={loadingDuration} />
          ) : (
            <div className={`content-container ${isMobile ? "mobile-view" : ""}`}>
              <Sidebar open={isSidebarOpen} setOpen={setSidebarOpen} setSelectedContent={setSelectedContent} toggleSidebar={toggleSidebar} />
              <main className="content">
                <Routes>
                  <Route path="/" element={<Home data={homeData} />} />  {/* Pass data to Home */}
                  <Route path="/List" element={<List data={listData} />} />
                  <Route path="/List_detail/:id/:tableName" element={<Detail data={detailData} />} />
                  <Route path="/Profile" element={<Profile data={profileData} />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </main>
            </div>
          )}
        </>
      ) : (
        <Loading duration={loadingDuration} /> // Show loading spinner while UI is loading
      )}
    </div>
  );
};

export default MainLayout;
