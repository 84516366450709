import React from 'react';
import { Slider, FormLabel } from '@mui/material';
import './Input.css';

const RangeSlider = ({ label, value, onChanged, min = 0, max = 100 }) => (
  <div>
    <FormLabel>{label}</FormLabel> {/* Display the label */}
    <Slider
      value={value}
      onChange={(e, newValue) => onChanged(newValue)} // Handle change event
      min={min}
      max={max}
      valueLabelDisplay="auto" // Shows the current value above the slider
    />
  </div>
);

export default RangeSlider;
