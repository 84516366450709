import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const DateBox = ({ label, value, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="date" // Input type for date picker
    value={value}
    onChange={onChanged}
    InputLabelProps={{ shrink: true }} // Keeps the label visible above the input
    fullWidth
    className="Add-Input"
  />
);

export default DateBox;
