import React from "react";
import { IoTrashOutline } from "react-icons/io5";
import { TbColumnsOff } from "react-icons/tb";
import { LuFileOutput, LuPenLine } from "react-icons/lu";
import { HiOutlineInboxIn } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";
import { MdAppSettingsAlt } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { GrUserSettings } from "react-icons/gr";
import { AiOutlineAppstore } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { AiOutlineAudit } from "react-icons/ai";
import { AiOutlineBank } from "react-icons/ai";
import { AiOutlineCodepen } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineHarmonyOS } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineHdd } from "react-icons/ai";
import { AiOutlineHolder } from "react-icons/ai";
import { AiOutlineIdcard } from "react-icons/ai";
import { AiOutlineHourglass } from "react-icons/ai";
import { AiOutlineShop } from "react-icons/ai";
import { AiOutlineShopping } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineTruck } from "react-icons/ai";
import { AiOutlineWallet } from "react-icons/ai";
import { AiOutlineProject } from "react-icons/ai";
import { AiOutlineGift } from "react-icons/ai";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsBackpack } from "react-icons/bs";
import { BsBackpack2 } from "react-icons/bs";
import { BsBackpack3 } from "react-icons/bs";
import { BsBackpack4 } from "react-icons/bs";
import { BsBagHeart } from "react-icons/bs";
import { BsBagPlus } from "react-icons/bs";
import { BsBarChart } from "react-icons/bs";
import { BsBalloonHeart } from "react-icons/bs";
import { BsBarChartSteps } from "react-icons/bs";
import { BsBoxSeamFill } from "react-icons/bs";
import { BsBoxSeam } from "react-icons/bs";
import { BsBriefcase } from "react-icons/bs";
import { BsBuildings } from "react-icons/bs";
import { BsBuilding } from "react-icons/bs";
import { BsCalendar } from "react-icons/bs";
import { BsCalendar2 } from "react-icons/bs";
import { BsCalendar2Check } from "react-icons/bs";
import { BsCalendar2Event } from "react-icons/bs";
import { BsCalendarWeek } from "react-icons/bs";
import { BsCart2 } from "react-icons/bs";
import { BsCreditCard2Front } from "react-icons/bs";
import { BsFlower1 } from "react-icons/bs";
import { BsFlower2 } from "react-icons/bs";
import { BsFolder2Open } from "react-icons/bs";
import { BsGraphUp } from "react-icons/bs";
import { BsGraphDown } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import { BsHospital } from "react-icons/bs";
import { BsHouseHeart } from "react-icons/bs";
import { BsHouseDoor } from "react-icons/bs";
import { BsHouseLock } from "react-icons/bs";
import { BsHouses } from "react-icons/bs";
import { BsHouseSlash } from "react-icons/bs";
import { BsHouseUp } from "react-icons/bs";
import { BsJournals } from "react-icons/bs";
import { BsKanban } from "react-icons/bs";
import { BsLamp } from "react-icons/bs";
import { BsMoisture } from "react-icons/bs";
import { BsMortarboard } from "react-icons/bs";
import { BsWrenchAdjustable } from "react-icons/bs";
import { BsWrench } from "react-icons/bs";
import { BsViewStacked } from "react-icons/bs";
import { BsToggles } from "react-icons/bs";
import { BsToggles2 } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsTable } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { BsStars } from "react-icons/bs";
import { BsSnow2 } from "react-icons/bs";
import { BsSlack } from "react-icons/bs";
import { BsShop } from "react-icons/bs";
import { BsReceipt } from "react-icons/bs";
import { BsReceiptCutoff } from "react-icons/bs";
import { BiArchive } from "react-icons/bi";
import { BiBarChart } from "react-icons/bi";
import { BiBarChartAlt } from "react-icons/bi";
import { BiBarChartAlt2 } from "react-icons/bi";
import { BiBarChartSquare } from "react-icons/bi";
import { BiBriefcase } from "react-icons/bi";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { BiBriefcaseAlt } from "react-icons/bi";
import { BiBuilding } from "react-icons/bi";
import { BiBuildingHouse } from "react-icons/bi";
import { BiBuildings } from "react-icons/bi";
import { BiCabinet } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { BiCalendarEvent } from "react-icons/bi";
import { BiCartAlt } from "react-icons/bi";
import { BiCategoryAlt } from "react-icons/bi";
import { BiCategory } from "react-icons/bi";
import { BiCart } from "react-icons/bi";
import { BiDollar } from "react-icons/bi";
import { BiDollarCircle } from "react-icons/bi";
import { BiEuro } from "react-icons/bi";
import { BiNotepad } from "react-icons/bi";
import { BiNoSignal } from "react-icons/bi";
import { BiObjectsHorizontalLeft } from "react-icons/bi";
import { BiObjectsVerticalBottom } from "react-icons/bi";
import { BiOutline } from "react-icons/bi";
import { BiPackage } from "react-icons/bi";
import { BiRuble } from "react-icons/bi";
import { BiRupee } from "react-icons/bi";
import { BiSignal3 } from "react-icons/bi";
import { BiSignal4 } from "react-icons/bi";
import { BiSignal5 } from "react-icons/bi";
import { BiSignal2 } from "react-icons/bi";
import { BiSpa } from "react-icons/bi";
import { CiAlignBottom } from "react-icons/ci";
import { CiAlignLeft } from "react-icons/ci";
import { CiBadgeDollar } from "react-icons/ci";
import { CiBag1 } from "react-icons/ci";
import { CiMicrophoneOff } from "react-icons/ci";
import { CiForkAndKnife } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { CiStethoscope } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";
import { CgDesignmodo } from "react-icons/cg";
import { CgShapeCircle } from "react-icons/cg";
import { FiBarChart2 } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiToggleRight } from "react-icons/fi";
import { FiToggleLeft } from "react-icons/fi";
import { TfiBarChart } from "react-icons/tfi";
import { TfiLayoutGrid2Thumb } from "react-icons/tfi";
import { TfiLayout } from "react-icons/tfi";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { TbCannabis } from "react-icons/tb";
import { TbChecks } from "react-icons/tb";
import { TbChecklist } from "react-icons/tb";
import { TbGridDots } from "react-icons/tb";
import { TbLayout } from "react-icons/tb";
import { TbLayoutBoard } from "react-icons/tb";
import { TbLayoutBoardSplit } from "react-icons/tb";
import { SiWolfram } from "react-icons/si";
import { SiVowpalwabbit } from "react-icons/si";
import { SiTga } from "react-icons/si";
import { SiSoundcharts } from "react-icons/si";
import { SiRubysinatra } from "react-icons/si";
import { SiParsedotly } from "react-icons/si";
import { SiJabber } from "react-icons/si";
import { SiGoogleanalytics } from "react-icons/si";
import { SiClyp } from "react-icons/si";
import { RiAppsLine } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { RiBarChartGroupedFill } from "react-icons/ri";
import { RiBuilding2Line } from "react-icons/ri";
import { RiBuildingLine } from "react-icons/ri";
import { RiGolfBallLine } from "react-icons/ri";
import { RiHeart2Line } from "react-icons/ri";
import { RiPantoneLine } from "react-icons/ri";
import { RiScissors2Line } from "react-icons/ri";
import { RiScales3Line } from "react-icons/ri";
import { RiToothLine } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { RxCardStackPlus } from "react-icons/rx";
import { RxCodesandboxLogo } from "react-icons/rx";
import { RxComponent1 } from "react-icons/rx";
import { RxCube } from "react-icons/rx";
import { PiBedDuotone } from "react-icons/pi";
import { PiBeachBallDuotone } from "react-icons/pi";
import { PiBell } from "react-icons/pi";
import { PiBicycleLight } from "react-icons/pi";
import { PiBirdLight } from "react-icons/pi";
import { PiBoneLight } from "react-icons/pi";
import { PiCarProfileLight } from "react-icons/pi";
import { PiCarLight } from "react-icons/pi";
import { PiCardholder } from "react-icons/pi";
import { PiCoffeeLight } from "react-icons/pi";
import { PiCookingPotLight } from "react-icons/pi";
import { PiCouchLight } from "react-icons/pi";
import { PiCrown } from "react-icons/pi";
import { PiCrownSimpleLight } from "react-icons/pi";
import { PiCurrencyGbpLight } from "react-icons/pi";
import { PiCurrencyJpyLight } from "react-icons/pi";
import { PiCurrencyKztLight } from "react-icons/pi";
import { PiCurrencyNgnLight } from "react-icons/pi";
import { PiCurrencyRubLight } from "react-icons/pi";
import { PiDeskLight } from "react-icons/pi";
import { PiDesktopTowerLight } from "react-icons/pi";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { PiEnvelopeSimpleOpenThin } from "react-icons/pi";
import { PiEyesFill } from "react-icons/pi";
import { PiGavelLight } from "react-icons/pi";
import { PiGlobeHemisphereWestDuotone } from "react-icons/pi";
import { PiGraduationCapLight } from "react-icons/pi";
import { PiGuitarLight } from "react-icons/pi";
import { PiHamburgerLight } from "react-icons/pi";
import { PiHairDryerLight } from "react-icons/pi";
import { PiHandPalmLight } from "react-icons/pi";
import { PiHandbagLight } from "react-icons/pi";
import { PiHeadsetLight } from "react-icons/pi";
import { PiIslandLight } from "react-icons/pi";
import { PiJeepLight } from "react-icons/pi";
import { PiLightbulbFilamentThin } from "react-icons/pi";
import { PiMartiniLight } from "react-icons/pi";
import { PiMaskSadLight } from "react-icons/pi";
import { PiMegaphoneLight } from "react-icons/pi";
import { PiMoneyWavyLight } from "react-icons/pi";
import { PiMoneyLight } from "react-icons/pi";
import { PiMoped } from "react-icons/pi";
import { PiMopedFront } from "react-icons/pi";
import { PiMotorcycleFill } from "react-icons/pi";
import { PiMusicNoteLight } from "react-icons/pi";
import { PiParkLight } from "react-icons/pi";
import { PiPizzaLight } from "react-icons/pi";
import { PiPlantLight } from "react-icons/pi";
import { PiPrinterLight } from "react-icons/pi";
import { PiProjectorScreen } from "react-icons/pi";
import { PiProjectorScreenChartLight } from "react-icons/pi";
import { PiScissorsFill } from "react-icons/pi";
import { PiScooter } from "react-icons/pi";
import { PiScrewdriverLight } from "react-icons/pi";
import { PiScrollLight } from "react-icons/pi";
import { PiSecurityCameraLight } from "react-icons/pi";
import { PiShippingContainerDuotone } from "react-icons/pi";
import { PiShoppingCartSimple } from "react-icons/pi";
import { PiShovelLight } from "react-icons/pi";
import { PiSketchLogoLight } from "react-icons/pi";
import { PiSpeedometerLight } from "react-icons/pi";
import { PiSphereLight } from "react-icons/pi";
import { PiStudentLight } from "react-icons/pi";
import { PiSyringeLight } from "react-icons/pi";
import { PiTennisBallFill } from "react-icons/pi";
import { PiTentLight } from "react-icons/pi";
import { PiTestTubeLight } from "react-icons/pi";
import { PiThermometerLight } from "react-icons/pi";
import { PiTipiFill } from "react-icons/pi";
import { PiTractorLight } from "react-icons/pi";
import { PiTrashLight } from "react-icons/pi";
import { PiTrayLight } from "react-icons/pi";
import { PiTrashSimpleThin } from "react-icons/pi";
import { PiTreeEvergreenThin } from "react-icons/pi";
import { PiTreePalmLight } from "react-icons/pi";
import { PiTreeLight } from "react-icons/pi";
import { PiTrolley } from "react-icons/pi";
import { PiTruckLight } from "react-icons/pi";
import { PiTruckTrailerLight } from "react-icons/pi";
import { PiUmbrellaThin } from "react-icons/pi";
import { PiVanLight } from "react-icons/pi";
import { PiVaultLight } from "react-icons/pi";
import { PiWalletLight } from "react-icons/pi";
import { PiWarehouseLight } from "react-icons/pi";
import { PiWallLight } from "react-icons/pi";
import { PiWashingMachineLight } from "react-icons/pi";
import { PiWheelchairLight } from "react-icons/pi";
import { PiWheelchairMotionLight } from "react-icons/pi";
import { PiWineLight } from "react-icons/pi";
import { PiWindLight } from "react-icons/pi";
import { MdBatteryCharging60 } from "react-icons/md";
import { MdCoffeeMaker } from "react-icons/md";
import { MdDirectionsBike } from "react-icons/md";
import { MdElectricBike } from "react-icons/md";
import { MdElectricCar } from "react-icons/md";
import { MdElectricScooter } from "react-icons/md";
import { MdElectricBolt } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineBlender } from "react-icons/md";
import { MdOutlineBrush } from "react-icons/md";
import { MdOutlineCached } from "react-icons/md";
import { MdOutlineCheck } from "react-icons/md";
import { MdOutlineClear } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import { MdOutlineGrass } from "react-icons/md";
import { MdOutlineTwoWheeler } from "react-icons/md";
import { MdQuestionMark } from "react-icons/md";
import { MdSailing } from "react-icons/md";
import { MdSatelliteAlt } from "react-icons/md";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import { MdSignalWifi3Bar } from "react-icons/md";
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md";
import { MdSignalWifiStatusbarNotConnected } from "react-icons/md";
import { MdSwitchAccessShortcutAdd } from "react-icons/md";
import { MdSync } from "react-icons/md";
import { MdSyncDisabled } from "react-icons/md";
import { MdSyncLock } from "react-icons/md";
import { MdSyncProblem } from "react-icons/md";
import { MdTerrain } from "react-icons/md";
import { MdThumbUpOffAlt } from "react-icons/md";
import { MdThumbDownOffAlt } from "react-icons/md";
import { MdTimeToLeave } from "react-icons/md";
import { MdWindPower } from "react-icons/md";
import { LuAirVent } from "react-icons/lu";
import { LuCake } from "react-icons/lu";
import { LuJapaneseYen } from "react-icons/lu";
import { LuPen } from "react-icons/lu";
import { LuPencil } from "react-icons/lu";
import { ImPencil2 } from "react-icons/im";
import { HiListBullet } from "react-icons/hi2";
import { HiLockClosed } from "react-icons/hi2";
import { HiLockOpen } from "react-icons/hi2";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { HiOutlineArrowDownTray } from "react-icons/hi2";
import { HiOutlinePresentationChartBar } from "react-icons/hi2";
import { ImBarcode } from "react-icons/im";
import { ImMan } from "react-icons/im";
import { ImManWoman } from "react-icons/im";
import { LiaAmbulanceSolid } from "react-icons/lia";
import { LiaCashRegisterSolid } from "react-icons/lia";
import { LiaCentos } from "react-icons/lia";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { LiaPenAltSolid } from "react-icons/lia";
import { LiaSignatureSolid } from "react-icons/lia";
import { LiaSpiderSolid } from "react-icons/lia";
import { LiaTheaterMasksSolid } from "react-icons/lia";
import { LiaWhmcs } from "react-icons/lia";
import { LiaYoutube } from "react-icons/lia";
import { GoArrowBoth } from "react-icons/go";
import { GoArrowSwitch } from "react-icons/go";
import { GoArrowDown } from "react-icons/go";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowDownRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import { GoArrowUp } from "react-icons/go";
import { GoArrowUpLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import { GoBeaker } from "react-icons/go";
import { GoBook } from "react-icons/go";
import { GoBookmark } from "react-icons/go";
import { GoBellSlash } from "react-icons/go";
import { GoBell } from "react-icons/go";
import { GoBookmarkSlash } from "react-icons/go";
import { GoCheckCircle } from "react-icons/go";
import { GoCircle } from "react-icons/go";
import { GoClock } from "react-icons/go";
import { GoCodeOfConduct } from "react-icons/go";
import { GoCommentDiscussion } from "react-icons/go";
import { GoPerson } from "react-icons/go";
import { GoThumbsup } from "react-icons/go";
import { GoThumbsdown } from "react-icons/go";
import { GoSun } from "react-icons/go";
import { GoTrophy } from "react-icons/go";
import { GoSquirrel } from "react-icons/go";
import { GoShieldCheck } from "react-icons/go";
import { GoShieldLock } from "react-icons/go";
import { GoCrossReference } from "react-icons/go";
import { GoFileMedia } from "react-icons/go";
import { GoKey } from "react-icons/go";
import { GoLaw } from "react-icons/go";
import { GoLocation } from "react-icons/go";
import { GoMilestone } from "react-icons/go";
import { CiCoffeeCup } from "react-icons/ci";
import { CiSaveUp2 } from "react-icons/ci";
import { IoCloudUploadOutline } from "react-icons/io5";
import { GiSuperMushroom } from "react-icons/gi";
import { BsUpcScan } from "react-icons/bs";
import { BsJournalArrowUp } from "react-icons/bs";
import { BsUpc } from "react-icons/bs";
import { BiVideoRecording } from "react-icons/bi";
import { RiRecordMailLine } from "react-icons/ri";
import { IoMdRecording } from "react-icons/io";
import { IoIosPhonePortrait } from "react-icons/io";
import { IoIosPhoneLandscape } from "react-icons/io";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { IoPhoneLandscapeOutline } from "react-icons/io5";
import { CiMicrophoneOn } from "react-icons/ci";
import { FaHeadphonesSimple } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { GiMicrophone } from "react-icons/gi";
import { TfiFaceSad } from "react-icons/tfi";
import { TfiFaceSmile } from "react-icons/tfi";
import { GrFacebookOption } from "react-icons/gr";
import { MdCurrencyFranc } from "react-icons/md";
import { TbCurrencyDinar } from "react-icons/tb";
import { TbCurrencyCent } from "react-icons/tb";
import { IoCarSport } from "react-icons/io5";
import { PiFadersHorizontalLight } from "react-icons/pi";
import { PiFadersThin } from "react-icons/pi";
import { PiFileRsLight } from "react-icons/pi";
import { LiaSpinnerSolid } from "react-icons/lia";
import { CiBank } from "react-icons/ci";
import { GiBank } from "react-icons/gi";
import { BsBank2 } from "react-icons/bs";
import { PiBank } from "react-icons/pi";
import { GiWingCloak,GiSeaDragon } from "react-icons/gi";
import { TbIcons } from "react-icons/tb";
import { FaIcons } from "react-icons/fa";


const icons = {
  TbColumnsOff: <TbColumnsOff />,
  LuFileOutput: <LuFileOutput />,
  IoTrashOutline: <IoTrashOutline />,
  LuPenLine: <LuPenLine />,
  FaChartBar: <FaChartBar />,
  HiOutlineInboxIn: <HiOutlineInboxIn />,
  AiOutlineSetting: <AiOutlineSetting />,
  MdAppSettingsAlt: <MdAppSettingsAlt />,
  MdOutlineAdminPanelSettings: <MdOutlineAdminPanelSettings />,
  MdOutlinePowerSettingsNew: <MdOutlinePowerSettingsNew />,
  MdOutlineDisplaySettings: <MdOutlineDisplaySettings />,
  GrUserSettings: <GrUserSettings />,
  AiOutlineAppstore: <AiOutlineAppstore />,
  AiOutlineAppstoreAdd: <AiOutlineAppstoreAdd />,
  AiOutlineAudit: <AiOutlineAudit />,
  AiOutlineBank: <AiOutlineBank />,
  AiOutlineCodepen: <AiOutlineCodepen />,
  AiOutlineHome: <AiOutlineHome />,
  AiOutlineHarmonyOS: <AiOutlineHarmonyOS />,
  AiOutlineHeart: <AiOutlineHeart />,
  AiOutlineHdd: <AiOutlineHdd />,
  AiOutlineHolder: <AiOutlineHolder />,
  AiOutlineIdcard: <AiOutlineIdcard />,
  AiOutlineHourglass: <AiOutlineHourglass />,
  AiOutlineShop: <AiOutlineShop />,
  AiOutlineShopping: <AiOutlineShopping />,
  AiOutlineShoppingCart: <AiOutlineShoppingCart />,
  AiOutlineTruck: <AiOutlineTruck />,
  AiOutlineWallet: <AiOutlineWallet />,
  AiOutlineProject: <AiOutlineProject />,
  AiOutlineGift: <AiOutlineGift />,
  AiOutlineCalendar: <AiOutlineCalendar />,
  BsBackpack: <BsBackpack />,
  BsBackpack2: <BsBackpack2 />,
  BsBackpack3: <BsBackpack3 />,
  BsBackpack4: <BsBackpack4 />,
  BsBagHeart: <BsBagHeart />,
  BsBagPlus: <BsBagPlus />,
  BsBarChart: <BsBarChart />,
  BsBalloonHeart: <BsBalloonHeart />,
  BsBarChartSteps: <BsBarChartSteps />,
  BsBoxSeamFill: <BsBoxSeamFill />,
  BsBoxSeam: <BsBoxSeam />,
  BsBriefcase: <BsBriefcase />,
  BsBuildings: <BsBuildings />,
  BsBuilding: <BsBuilding />,
  BsCalendar: <BsCalendar />,
  BsCalendar2: <BsCalendar2 />,
  BsCalendar2Check: <BsCalendar2Check />,
  BsCalendar2Event: <BsCalendar2Event />,
  BsCalendarWeek: <BsCalendarWeek />,
  BsCart2: <BsCart2 />,
  BsCreditCard2Front: <BsCreditCard2Front />,
  BsFlower1: <BsFlower1 />,
  BsFlower2: <BsFlower2 />,  
  BsFolder2Open: <BsFolder2Open />,
  BsGraphUp: <BsGraphUp />,
  BsGraphDown: <BsGraphDown />,
  BsHeart: <BsHeart />,
  BsHospital: <BsHospital />,
  BsHouseHeart: <BsHouseHeart />,
  BsHouseDoor: <BsHouseDoor />,
  BsHouseLock: <BsHouseLock />,
  BsHouses: <BsHouses />,
  BsHouseSlash: <BsHouseSlash />,
  BsHouseUp: <BsHouseUp />,
  BsJournals: <BsJournals />,
  BsKanban: <BsKanban />,
  BsLamp: <BsLamp />,
  BsMoisture: <BsMoisture />,
  BsMortarboard: <BsMortarboard />,
  BsWrenchAdjustable: <BsWrenchAdjustable />,
  BsWrench: <BsWrench />,
  BsViewStacked: <BsViewStacked />,
  BsToggles: <BsToggles />,
  BsToggles2: <BsToggles2 />,
  BsThreeDots: <BsThreeDots />,
  BsThreeDotsVertical: <BsThreeDotsVertical />,
  BsTable: <BsTable />,
  BsStar: <BsStar />,
  BsStars: <BsStars />,
  BsSnow2: <BsSnow2 />,
  BsSlack: <BsSlack />,
  BsShop: <BsShop />,
  BsReceipt: <BsReceipt />,
  BsReceiptCutoff: <BsReceiptCutoff />,
  BiArchive: <BiArchive />,
  BiBarChart: <BiBarChart />,
  BiBarChartAlt: <BiBarChartAlt />,
  BiBarChartAlt2: <BiBarChartAlt2 />,
  BiBarChartSquare: <BiBarChartSquare />,
  BiBriefcase: <BiBriefcase />,
  BiBriefcaseAlt2: <BiBriefcaseAlt2 />,
  BiBriefcaseAlt: <BiBriefcaseAlt />,
  BiBuilding: <BiBuilding />,
  BiBuildingHouse: <BiBuildingHouse />,
  BiBuildings: <BiBuildings />,
  BiCabinet: <BiCabinet />,
  BiCalendar: <BiCalendar />,
  BiCalendarEvent: <BiCalendarEvent />,
  BiCartAlt: <BiCartAlt />,
  BiCategoryAlt: <BiCategoryAlt />,
  BiCategory: <BiCategory />,
  BiCart: <BiCart />,
  BiDollar: <BiDollar />,
  BiDollarCircle: <BiDollarCircle />,
  BiEuro: <BiEuro />,
  BiNotepad: <BiNotepad />,
  BiNoSignal: <BiNoSignal />,
  BiObjectsHorizontalLeft: <BiObjectsHorizontalLeft />,
  BiObjectsVerticalBottom: <BiObjectsVerticalBottom />,
  BiOutline: <BiOutline />,
  BiPackage: <BiPackage />,
  BiRuble: <BiRuble />,
  BiRupee: <BiRupee />,
  BiSignal2: <BiSignal2 />,
  BiSignal3: <BiSignal3 />,
  BiSignal4: <BiSignal4 />,
  BiSignal5: <BiSignal5 />,
  BiSpa: <BiSpa />,
  CiAlignBottom: <CiAlignBottom />,
  CiAlignLeft: <CiAlignLeft />,
  CiBadgeDollar: <CiBadgeDollar />,
  CiBag1: <CiBag1 />,
  CiForkAndKnife: <CiForkAndKnife />,  
  CiUser: <CiUser />,
  CiStethoscope: <CiStethoscope />,
  CiShoppingCart: <CiShoppingCart />,
  CgDesignmodo: <CgDesignmodo />,
  CgShapeCircle: <CgShapeCircle />,
  FiBarChart2: <FiBarChart2 />,
  FiMail: <FiMail />,
  FiToggleRight: <FiToggleRight />,
  FiToggleLeft: <FiToggleLeft />,
  TfiBarChart: <TfiBarChart />,
  TfiLayoutGrid2Thumb: <TfiLayoutGrid2Thumb />,
  TfiLayout: <TfiLayout />,
  TbBrandGoogleAnalytics: <TbBrandGoogleAnalytics />,
  TbCannabis: <TbCannabis />,
  TbChecks: <TbChecks />,
  TbChecklist: <TbChecklist />,
  TbGridDots: <TbGridDots />,
  TbLayout: <TbLayout />,
  TbLayoutBoard: <TbLayoutBoard />,
  TbLayoutBoardSplit: <TbLayoutBoardSplit />,
  SiWolfram: <SiWolfram />,
  SiVowpalwabbit: <SiVowpalwabbit />,
  SiTga: <SiTga />,
  SiSoundcharts: <SiSoundcharts />,
  SiRubysinatra: <SiRubysinatra />,
  SiParsedotly: <SiParsedotly />,
  SiJabber: <SiJabber />,
  SiGoogleanalytics: <SiGoogleanalytics />,
  SiClyp: <SiClyp />,
  RiAppsLine: <RiAppsLine />,
  RiBarChartLine: <RiBarChartLine />,
  RiBarChartGroupedFill: <RiBarChartGroupedFill />,
  RiBuilding2Line: <RiBuilding2Line />,
  RiBuildingLine: <RiBuildingLine />,
  RiGolfBallLine: <RiGolfBallLine />,
  RiHeart2Line: <RiHeart2Line />,
  RiPantoneLine: <RiPantoneLine />,
  RiScissors2Line: <RiScissors2Line />,
  RiScales3Line: <RiScales3Line />,
  RiToothLine: <RiToothLine />,
  RxCalendar: <RxCalendar />,
  RxCardStackPlus: <RxCardStackPlus />,
  RxCodesandboxLogo: <RxCodesandboxLogo />,
  RxComponent1: <RxComponent1 />,
  RxCube: <RxCube />,
  PiBedDuotone: <PiBedDuotone />,
  PiBeachBallDuotone: <PiBeachBallDuotone />,
  PiBell: <PiBell />,
  PiBicycleLight: <PiBicycleLight />,
  PiBirdLight: <PiBirdLight />,
  PiBoneLight: <PiBoneLight />,
  PiCarProfileLight: <PiCarProfileLight />,
  PiCarLight: <PiCarLight />,
  PiCardholder: <PiCardholder />,
  PiCoffeeLight: <PiCoffeeLight />,
  PiCookingPotLight: <PiCookingPotLight />,
  PiCouchLight: <PiCouchLight />,
  PiCrown: <PiCrown />,
  PiCrownSimpleLight: <PiCrownSimpleLight />,
  PiCurrencyGbpLight: <PiCurrencyGbpLight />,
  PiCurrencyJpyLight: <PiCurrencyJpyLight />,
  PiCurrencyKztLight: <PiCurrencyKztLight />,
  PiCurrencyNgnLight: <PiCurrencyNgnLight />,
  PiCurrencyRubLight: <PiCurrencyRubLight />,
  PiDeskLight: <PiDeskLight />,
  PiDesktopTowerLight: <PiDesktopTowerLight />,
  PiEnvelopeSimpleThin: <PiEnvelopeSimpleThin />,
  PiEnvelopeSimpleOpenThin: <PiEnvelopeSimpleOpenThin />,
  PiEyesFill: <PiEyesFill />,
  PiGavelLight: <PiGavelLight />,
  PiGlobeHemisphereWestDuotone: <PiGlobeHemisphereWestDuotone />,
  PiGraduationCapLight: <PiGraduationCapLight />,
  PiGuitarLight: <PiGuitarLight />,
  PiHamburgerLight: <PiHamburgerLight />,
  PiHairDryerLight: <PiHairDryerLight />,
  PiHandPalmLight: <PiHandPalmLight />,
  PiHandbagLight: <PiHandbagLight />,
  PiHeadsetLight: <PiHeadsetLight />,
  PiIslandLight: <PiIslandLight />,
  PiJeepLight: <PiJeepLight />,
  PiLightbulbFilamentThin: <PiLightbulbFilamentThin />,
  PiMartiniLight: <PiMartiniLight />,
  PiMaskSadLight: <PiMaskSadLight />,
  PiMegaphoneLight: <PiMegaphoneLight />,
  PiMoneyWavyLight: <PiMoneyWavyLight />,
  PiMoneyLight: <PiMoneyLight />,
  PiMoped: <PiMoped />,
  PiMopedFront: <PiMopedFront />,
  PiMotorcycleFill: <PiMotorcycleFill />,
  PiMusicNoteLight: <PiMusicNoteLight />,
  PiParkLight: <PiParkLight />,
  PiPizzaLight: <PiPizzaLight />,
  PiPlantLight: <PiPlantLight />,
  PiPrinterLight: <PiPrinterLight />,
  PiProjectorScreen: <PiProjectorScreen />,
  PiProjectorScreenChartLight: <PiProjectorScreenChartLight />,
  PiScissorsFill: <PiScissorsFill />,
  PiScooter: <PiScooter />,
  PiScrewdriverLight: <PiScrewdriverLight />,
  PiScrollLight: <PiScrollLight />,
  PiSecurityCameraLight: <PiSecurityCameraLight />,
  PiShippingContainerDuotone: <PiShippingContainerDuotone />,
  PiShoppingCartSimple: <PiShoppingCartSimple />,
  PiShovelLight: <PiShovelLight />,
  PiSketchLogoLight: <PiSketchLogoLight />,
  PiSpeedometerLight: <PiSpeedometerLight />,
  PiSphereLight: <PiSphereLight />,
  PiStudentLight: <PiStudentLight />,
  PiSyringeLight: <PiSyringeLight />,
  PiTennisBallFill: <PiTennisBallFill />,
  PiTentLight: <PiTentLight />,
  PiTestTubeLight: <PiTestTubeLight />,
  PiThermometerLight: <PiThermometerLight />,
  PiTipiFill: <PiTipiFill />,
  PiTractorLight: <PiTractorLight />,
  PiTrashLight: <PiTrashLight />,
  PiTrayLight: <PiTrayLight />,
  PiTrashSimpleThin: <PiTrashSimpleThin />,
  PiTreeEvergreenThin: <PiTreeEvergreenThin />,
  PiTreePalmLight: <PiTreePalmLight />,
  PiTreeLight: <PiTreeLight />,
  PiTrolley: <PiTrolley />,
  PiTruckLight: <PiTruckLight />,
  PiTruckTrailerLight: <PiTruckTrailerLight />,
  PiUmbrellaThin: <PiUmbrellaThin />,
  PiVanLight: <PiVanLight />,
  PiVaultLight: <PiVaultLight />,
  PiWalletLight: <PiWalletLight />,
  PiWarehouseLight: <PiWarehouseLight />,
  PiWallLight: <PiWallLight />,
  PiWashingMachineLight: <PiWashingMachineLight />,
  PiWheelchairLight: <PiWheelchairLight />,
  PiWheelchairMotionLight: <PiWheelchairMotionLight />,
  PiWineLight: <PiWineLight />,
  PiWindLight: <PiWindLight />,
  MdBatteryCharging60: <MdBatteryCharging60 />,
  MdCoffeeMaker: <MdCoffeeMaker />,
  MdDirectionsBike: <MdDirectionsBike />,
  MdElectricBike: <MdElectricBike />,
  MdElectricCar: <MdElectricCar />,
  MdElectricScooter: <MdElectricScooter />,
  MdElectricBolt: <MdElectricBolt />,
  MdModeEdit: <MdModeEdit />,
  MdOutlineBlender: <MdOutlineBlender />,
  MdOutlineBrush: <MdOutlineBrush />,
  MdOutlineCached: <MdOutlineCached />,
  MdOutlineCheck: <MdOutlineCheck />,
  MdOutlineClear: <MdOutlineClear />,
  MdOutlineChevronRight: <MdOutlineChevronRight />,
  MdOutlineChevronLeft: <MdOutlineChevronLeft />,
  MdOutlineCreate: <MdOutlineCreate />,
  MdOutlineGrass: <MdOutlineGrass />,
  MdOutlineTwoWheeler: <MdOutlineTwoWheeler />,
  MdQuestionMark: <MdQuestionMark />,
  MdSailing: <MdSailing />,
  MdSatelliteAlt: <MdSatelliteAlt />,
  MdSignalWifiConnectedNoInternet0: <MdSignalWifiConnectedNoInternet0 />,
  MdSignalWifiStatusbarConnectedNoInternet: <MdSignalWifiStatusbarConnectedNoInternet />,
  MdSignalWifiStatusbarNotConnected: <MdSignalWifiStatusbarNotConnected />,
  MdSignalWifi3Bar: <MdSignalWifi3Bar />,
  MdSwitchAccessShortcutAdd: <MdSwitchAccessShortcutAdd />,
  MdSync: <MdSync />,
  MdSyncDisabled: <MdSyncDisabled />,
  MdSyncLock: <MdSyncLock />,
  MdSyncProblem: <MdSyncProblem />,
  MdTerrain: <MdTerrain />,
  MdThumbUpOffAlt: <MdThumbUpOffAlt />,
  MdThumbDownOffAlt: <MdThumbDownOffAlt />,
  MdTimeToLeave: <MdTimeToLeave />,
  MdWindPower: <MdWindPower />,
  LuAirVent: <LuAirVent />,
  LuCake: <LuCake />,
  LuJapaneseYen: <LuJapaneseYen />,
  LuPen: <LuPen />,
  LuPencil: <LuPencil />,
  ImPencil2: <ImPencil2 />,
  HiListBullet: <HiListBullet />,
  HiLockClosed: <HiLockClosed />,
  HiLockOpen: <HiLockOpen />,
  HiMagnifyingGlass: <HiMagnifyingGlass />,  
  HiOutlineArrowDownTray: <HiOutlineArrowDownTray />,
  HiOutlinePresentationChartBar: <HiOutlinePresentationChartBar />,
  ImBarcode: <ImBarcode />,
  ImMan: <ImMan />,
  ImManWoman: <ImManWoman />,
  LiaAmbulanceSolid: <LiaAmbulanceSolid />,
  LiaCashRegisterSolid: <LiaCashRegisterSolid />,
  LiaCentos: <LiaCentos />,
  LiaGlobeAmericasSolid: <LiaGlobeAmericasSolid />,
  LiaMoneyCheckAltSolid: <LiaMoneyCheckAltSolid />,
  LiaPenAltSolid: <LiaPenAltSolid />,
  LiaSignatureSolid: <LiaSignatureSolid />,
  LiaSpiderSolid: <LiaSpiderSolid />,
  LiaTheaterMasksSolid: <LiaTheaterMasksSolid />,
  LiaWhmcs: <LiaWhmcs />,
  LiaYoutube: <LiaYoutube />,
  GoArrowBoth: <GoArrowBoth />,
  GoArrowSwitch: <GoArrowSwitch />,
  GoArrowDown: <GoArrowDown />,
  GoArrowDownLeft: <GoArrowDownLeft />,
  GoArrowDownRight: <GoArrowDownRight />,
  GoArrowLeft: <GoArrowLeft />,
  GoArrowRight: <GoArrowRight />,
  GoArrowUp: <GoArrowUp />,
  GoArrowUpLeft: <GoArrowUpLeft />,
  GoArrowUpRight: <GoArrowUpRight />,
  GoBeaker: <GoBeaker />,
  GoBook: <GoBook />,
  GoBookmark: <GoBookmark />,
  GoBellSlash: <GoBellSlash />,
  GoBell: <GoBell />,
  GoBookmarkSlash: <GoBookmarkSlash />,
  GoCheckCircle: <GoCheckCircle />,
  GoCircle: <GoCircle />,
  GoClock: <GoClock />,
  GoCodeOfConduct: <GoCodeOfConduct />,
  GoCommentDiscussion: <GoCommentDiscussion />,
  GoPerson: <GoPerson />,
  GoThumbsup: <GoThumbsup />,
  GoThumbsdown: <GoThumbsdown />,
  GoSun: <GoSun />,
  GoTrophy: <GoTrophy />,
  GoSquirrel: <GoSquirrel />,
  GoShieldCheck: <GoShieldCheck />,
  GoShieldLock: <GoShieldLock />,
  GoCrossReference: <GoCrossReference />,
  GoFileMedia: <GoFileMedia />,
  GoKey: <GoKey />,
  GoLaw: <GoLaw />,
  GoLocation: <GoLocation />,
  GoMilestone: <GoMilestone />,
  CiCoffeeCup: <CiCoffeeCup />,
  CiSaveUp2: <CiSaveUp2 />,
  IoCloudUploadOutline: <IoCloudUploadOutline />,
  GiSuperMushroom: <GiSuperMushroom />,
  BsUpcScan: <BsUpcScan />,
  BsJournalArrowUp: <BsJournalArrowUp />,
  BsUpc: <BsUpc />,
  BiVideoRecording: <BiVideoRecording />,
  RiRecordMailLine: <RiRecordMailLine />,
  IoMdRecording: <IoMdRecording />,
  IoIosPhonePortrait: <IoIosPhonePortrait />,
  IoIosPhoneLandscape: <IoIosPhoneLandscape />,
  IoPhonePortraitOutline: <IoPhonePortraitOutline />,
  IoPhoneLandscapeOutline: <IoPhoneLandscapeOutline />,
  CiMicrophoneOn: <CiMicrophoneOn />,
  CiMicrophoneOff: <CiMicrophoneOff />,
  FaHeadphonesSimple: <FaHeadphonesSimple />,
  FiPhone: <FiPhone />,
  GiMicrophone: <GiMicrophone />,
  TfiFaceSad: <TfiFaceSad />,
  TfiFaceSmile: <TfiFaceSmile />,
  GrFacebookOption: <GrFacebookOption />,
  MdCurrencyFranc: <MdCurrencyFranc />,
  TbCurrencyDinar: <TbCurrencyDinar />,
  TbCurrencyCent: <TbCurrencyCent />,
  IoCarSport: <IoCarSport />,
  PiFadersHorizontalLight: <PiFadersHorizontalLight />,
  PiFadersThin: <PiFadersThin />,
  PiFileRsLight: <PiFileRsLight />,
  LiaSpinnerSolid: <LiaSpinnerSolid />,
  CiBank: <CiBank />,
  GiBank: <GiBank />,
  BsBank2: <BsBank2 />,
  PiBank: <PiBank />,
  GiWingCloak:<GiWingCloak />,
  GiSeaDragon:<GiSeaDragon />,
  TbIcons:<TbIcons />,
  FaIcons:<FaIcons />,
};

export async function getIconComponent(iconName) {
  return icons[iconName] || null;
}
