import React from 'react';

const CheckBox = ({ value, onValueChanged, readOnly }) => (
  <input
    type="checkbox"
    checked={value}
    onChange={onValueChanged}
    disabled={readOnly}
  />
);

export default CheckBox;
