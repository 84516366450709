import React from 'react';

const TimeBox = ({ value, onValueChanged, readOnly }) => (
  <input
    type="time"
    value={value}
    onChange={onValueChanged}
    readOnly={readOnly}
  />
);

export default TimeBox;
