import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './Input.css';

const SelectBox = ({ label, value, onChanged, options }) => (
  <FormControl fullWidth variant="outlined"> {/* Full width and outlined variant for consistency */}
    <InputLabel>{label}</InputLabel> {/* Label for the select box */}
    <Select
      value={value}
      onChange={onChanged}
      label={label} // Associate the label with the select box
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectBox;
