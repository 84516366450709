import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import "./Sidebar.css";
import { BASE_URL } from "../url/base.js";
import { getIconComponent } from "./getIconComponent.js";

// Utility function to truncate text if it exceeds a certain length
const truncateText = (text, length = 18) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

// Main TreeItem component for the main sidebar items
function TreeItem({ label, icon, onClick, hasChildren = false, isOpen, toggleOpen }) {
  return (
    <div className="tree-item">
      <div onClick={onClick} className="t-item">
        {icon && <div className="icon">{icon}</div>}
        <div className="text">{truncateText(label)}</div>
        {hasChildren && (
         <div className="arrow" onClick={(e) => { 
          e.stopPropagation(); // Prevent triggering the onClick
          toggleOpen(); 
        }}>
          {isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </div>
        )}
      </div>
    </div>
  );
}

// Custom SubItem component for sub-items
function SubItem({ label, onClick }) {
  return (
    <div className="sub-item" onClick={onClick}>
      {truncateText(label)}
    </div>
  );
}

function Sidebar({ open, setSelectedContent, setOpen, toggleSidebar }) {
  const [dynamicItems, setDynamicItems] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch data once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${BASE_URL}srecord/app`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(response.status === 403 ? "Permission denied." : `Error: ${response.statusText}`);
        }

        const appData = await response.json();
        const items = await Promise.all(
          appData.data.map(async (appItem) => {
            const relatedUrl = `${BASE_URL}srecord/app/${appItem.id}/related/apptab/app_id`;
            const relatedResponse = await fetch(relatedUrl, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });

            if (!relatedResponse.ok) {
              throw new Error(`Error fetching related data for ID ${appItem.id}: ${relatedResponse.statusText}`);
            }

            const relatedData = await relatedResponse.json();
            const iconValue = appItem.icon?.value;
            const mainIcon = await getIconComponent(iconValue);
            const subItems = relatedData.data.map((subItem) => ({
              text: subItem.label,
              name: subItem.name,
            }));

            return {
              text: appItem.name,
              icon: mainIcon,
              items: subItems,
            };
          })
        );

        setDynamicItems(items);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures fetchData runs once on mount


  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    if (!open) {
      setOpenSections({});
    }
  }, [open]);

  const handleSubItemClick = (subItemName) => {
    navigate(`/List?tab=${subItemName}`);
    setSelectedContent(<div>Selected Tab: {subItemName}</div>);
  };

  if (loading) {
    return <div>Loading sidebar...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`sidebar ${open ? "expanded" : "collapsed"}`}>
      {dynamicItems.map((mainItem, index) => (
        <div key={index}>
          <TreeItem
            label={mainItem.text}
            icon={mainItem.icon}
            hasChildren={!!mainItem.items.length}
            isOpen={!!openSections[index]}
            onClick={() => {
              if (!open) toggleSidebar(); // Expand the sidebar if it's collapsed
              toggleSection(index); // Open/close the sub-items
            }}
            toggleOpen={() => toggleSection(index)}
          />
          {!!openSections[index] && mainItem.items && (
            <div className="sub">
              {mainItem.items.map((subItem, subIndex) => (
                <SubItem
                  key={subIndex}
                  label={subItem.text}
                  onClick={() => handleSubItemClick(subItem.name)}
                />
              ))}
            </div>
          )}
          <div className="separater" />
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
