import React, { useState } from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const MarkdownEditor = ({ label, value, onChanged }) => {
  const [text, setText] = useState(value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setText(newValue);
    onChanged({ value: newValue });
  };

  return (
    <TextField
      label={label} // Display the label
      value={text}
      onChange={handleChange}
      multiline // Enables multiline input for Markdown
      rows={4} // Sets the number of visible rows
      variant="outlined" // You can use "outlined" or "filled" based on your design preference
      fullWidth
      InputLabelProps={{ shrink: true }} // Keeps the label visible above the input
      className="Add-Input"
    />
  );
};

export default MarkdownEditor;
