import React, { useState } from 'react';

const MarkdownEditor = ({ value, onValueChanged, readOnly }) => {
  const [text, setText] = useState(value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setText(newValue);
    onValueChanged({ value: newValue });
  };

  return (
    <textarea value={text} onChange={handleChange} readOnly={readOnly} />
  );
};

export default MarkdownEditor;
