import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const FileUpload = ({ label, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="file" // Input type for file upload
    InputLabelProps={{ shrink: true }} // Keeps the label visible
    onChange={onChanged}
    fullWidth
    className="Add-Input"
  />
);

export default FileUpload;
