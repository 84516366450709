import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const TimeBox = ({ label, value, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="time" // Input type for time picker
    value={value}
    onChange={onChanged}
    InputLabelProps={{ shrink: true }} // Ensures the label stays visible
    fullWidth // Makes the input full width
    variant="outlined" // You can choose "outlined", "filled", etc.
    className="Add-Input"
  />
);

export default TimeBox;
