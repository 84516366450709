import React from "react";
import { Box, Button, Grid2, Modal, Typography } from "@mui/material";
import InputTypes from './InputTypes'; // Reuse your InputTypes component
import './AddForm.css';

const AddForm = ({ setIsModalOpen, newRow, setNewRow, columns, onSave }) => {
    const handleInputChange = (e, name, value = null) => {
        const newValue = e
          ? e.target.type === "checkbox"
            ? e.target.checked
            : e.target.value
          : value;
    
        setNewRow((prev) => ({
          ...prev,
          [name]: newValue,
        }));
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal open={true} onClose={handleClose} className="AddForm">
            <Box className="modal-box">
                <Typography variant="h6" gutterBottom>
                    Add New Record
                </Typography>
                <Grid2 container spacing={2}>
                    {columns.map((column) => {
                        if (!column.visible) return null;
                        return (
                            <Grid2 xs={12} sm={6} key={column.name}>
                                <InputTypes
                                    type={column.type}
                                    value={newRow[column.name] || ""}
                                    onChange={(e) => handleInputChange(e, column.name)}
                                    options={column.options || []}
                                    placeholder={column.placeholder || ""}
                                    onChanged={(value) => handleInputChange(null, column.name, value)}
                                    required={column.required || false}
                                    relatedTo={column.related_to}
                                    idField={column.id_field}
                                    label={column.label}
                                />
                            </Grid2>
                        );
                    })}
                </Grid2>
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose} className="cancel-btn">
                        Cancel
                    </Button>
                    <Button
                        className="save-btn"
                        onClick={() => {
                            onSave(newRow);
                        }}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddForm;
