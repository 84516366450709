import React from 'react';

const RangeSlider = ({ value, onValueChanged, readOnly, min = 0, max = 100 }) => (
  <input
    type="range"
    value={value}
    onChange={onValueChanged}
    min={min}
    max={max}
    disabled={readOnly}
  />
);

export default RangeSlider;
