import React from 'react';

const NumberBox = ({ value, onValueChanged, readOnly }) => (
  <input
    type="number"
    value={value}
    onChange={onValueChanged}
    readOnly={readOnly}
  />
);

export default NumberBox;
