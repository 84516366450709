import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import List from "../List/List";
import DetailForm from "../../components/DetailForm/DetailForm";
import { BASE_URL } from "../../url/base";
import { IoChevronBackSharp } from "react-icons/io5";
import { IconButton } from "@mui/material";
import "./Detail.css";

const Detail = () => {
  const navigate = useNavigate();
  const { id, tableName } = useParams();
  const [detailData, setDetailData] = useState({});
  const [columns, setColumns] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [idError, setIdError] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchDetailData = async () => {
      if (!id || !tableName) {
        setIdError("Please contact administrator");
        return;
      }

      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found in localStorage");

        // Fetch detail data
        const detailResponse = await fetch(`${BASE_URL}srecord/${tableName}/${id}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!detailResponse.ok) throw new Error("Failed to fetch detail data");

        const detailResult = await detailResponse.json();
        setDetailData(detailResult.data || {});
        setColumns(detailResult.columns || []);

        // Fetch related configuration
        const relatedResponse = await fetch(`${BASE_URL}srecord/${tableName}/related`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!relatedResponse.ok) throw new Error("Failed to fetch related config");

        const relatedResult = await relatedResponse.json();
        setTabs(relatedResult.columns || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDetailData();
  }, [id, tableName]);

  const handleBackButtonClick = () => navigate(-1);

  return (
    <div className="details-container">
      <div className="detail-header">
        <IconButton onClick={handleBackButtonClick}>
          <IoChevronBackSharp />
        </IconButton>
      </div>
      <div className="panels">
        <div className="left-panel">
          <DetailForm
            data={detailData}
            id={id}
            tableName={tableName}
            columns={columns}
          />
        </div>
        {idError && <p style={{ color: "red" }}>{idError}</p>}
        <div className="right-panel">
          <div className="tab-container">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
              >
                {tab.related_to_label}
              </button>
            ))}
          </div>
          {tabs.length > 0 && (
            <List
              tableName={tableName}
              id={id}
              tabConfig={tabs[activeTab]}
              detailData={detailData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
