import { useState } from 'react';

export default function useThemeManager() {
  const [theme, setTheme] = useState("metallic2");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleThemeChange = (selectedTheme) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setTheme(selectedTheme);
      setIsTransitioning(false);
    }, 1000);
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return {
    theme,
    isDarkMode,
    sidebarOpen,
    isTransitioning,
    handleThemeChange,
    toggleDarkMode,
    toggleSidebar,
  };
}
