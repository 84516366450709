import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill Snow theme CSS

const RichTextEditor = ({ value, onValueChanged, readOnly }) => (
  <ReactQuill
    value={value}
    onChange={onValueChanged}
    readOnly={readOnly}
    theme="snow" // Theme for rich text editor
  />
);

export default RichTextEditor;
