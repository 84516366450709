import React from 'react';

const RadioButtonGroup = ({ value, onValueChanged, options, readOnly }) => (
  <div>
    {options.map((option, index) => (
      <label key={index}>
        <input
          type="radio"
          value={option.value}
          checked={value === option.value}
          onChange={onValueChanged}
          disabled={readOnly}
        />
        {option.label}
      </label>
    ))}
  </div>
);

export default RadioButtonGroup;
