import React from 'react';
import * as Inputs from '../CommonInputs'; // Import all input components
import PropTypes from 'prop-types';

const InputTypes = ({
  type,
  value,
  onChange,
  onChanged,
  options = [],
  placeholder,
  relatedTo = null,
  readOnly = false,
  ...rest
}) => {
  // Component Map: Maps input types to their respective components
  const componentsMap = {
    select: Inputs.SelectBox,
    textarea: Inputs.TextArea,
    number: Inputs.NumberBox,
    checkbox: Inputs.CheckBox,
    Checkbox: Inputs.CheckBox,
    date: Inputs.DateBox,
    Date: Inputs.DateBox,
    text: Inputs.TextArea,
    string: Inputs.TextArea,
    Icon: Inputs.Lookup,
    Lookup: Inputs.Lookup,
    lookup: Inputs.Lookup,
    password: Inputs.PasswordBox,
    email: Inputs.EmailBox,
    phone: Inputs.PhoneBox,
    url: Inputs.URLBox,
    radio: Inputs.RadioButtonGroup,
    time: Inputs.TimeBox,
    file: Inputs.FileUpload,
    range: Inputs.RangeSlider,
    color: Inputs.ColorPicker,
    toggle: Inputs.ToggleSwitch,
    markdown: Inputs.MarkdownEditor,
    richtext: Inputs.RichTextEditor,
    signature: Inputs.SignatureBox,
    "Date & Time": Inputs.DateTime,
  };

  // Dynamically select the component based on the type
  const InputComponent = componentsMap[type];

  if (!InputComponent) {
    console.warn(`Unsupported input type: ${type}`);
    return <div>Unsupported input type: {type}</div>;
  }

  // Additional props based on type
  const additionalProps = {};
  if (['radio', 'select'].includes(type)) {
    additionalProps.options = options;
  }
  if (['Icon', 'Lookup', 'lookup'].includes(type)) {
    additionalProps.relatedTo = relatedTo;
  }

  return (
    <InputComponent
      value={value}
      onChange={onChange}
      onChanged={onChanged}
      placeholder={placeholder}
      disabled={readOnly}
      {...additionalProps}
      {...rest}
    />
  );
};

InputTypes.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onChanged: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  relatedTo: PropTypes.string,
  readOnly: PropTypes.bool,
};

InputTypes.defaultProps = {
  value: '',
  onChanged: null,
  options: [],
  placeholder: '',
  relatedTo: '',
  readOnly: false,
};

export default InputTypes;