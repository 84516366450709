import React from 'react';

const SelectBox = ({ value, onValueChanged, readOnly, options }) => (
  <select value={value} onChange={onValueChanged} disabled={readOnly}>
    {options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

export default SelectBox;
