import React from 'react';
import { FormControl, FormControlLabel, Radio, FormLabel } from '@mui/material';
import './Input.css';

const RadioButtonGroup = ({ label, value, onChanged, options }) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">{label}</FormLabel> {/* Display the group label */}
    {options.map((option, index) => (
      <FormControlLabel
        key={index}
        control={
          <Radio
            value={option.value}
            checked={value === option.value}
            onChange={onChanged}
          />
        }
        label={option.label} // Display the option label
      />
    ))}
  </FormControl>
);

export default RadioButtonGroup;
