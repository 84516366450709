import { BASE_URL } from './url/base';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import MainLayout from "./layouts/MainLayout";
import SignInForm from './unauth/Login/SignInForm';
import SignUpForm from './unauth/Create/SignUpForm';
import ResetPasswordForm from './unauth/Reset/ResetPasswordForm';
import ThemeTransition from './layouts/ThemeTransition';

import './index.css';
import './themes/index.css';

// Utility hooks or handlers (logic extracted for reusability)
import useThemeManager from './themes/useThemeManager';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially set to null to indicate loading
  const [loading, setLoading] = useState(true); // To track the loading state

  const {
    theme,
    isDarkMode,
    sidebarOpen,
    isTransitioning,
    handleThemeChange,
    toggleDarkMode,
    toggleSidebar,
  } = useThemeManager();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setIsAuthenticated(false); // No token, set isAuthenticated to false
      setLoading(false); // Set loading to false since we have completed the check
      return;
    }

    // If token exists, validate it with the API
    const checkAuth = async () => {
      try {
        const response = await fetch(`${BASE_URL}/validate_token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (response.status === 200) {
          setIsAuthenticated(true); // Token is valid, user is authenticated
        } else {
          setIsAuthenticated(false); // Token is invalid, set as not authenticated
        }
      } catch (error) {
        console.error("Error during token validation:", error);
        setIsAuthenticated(false); // If error occurs, assume not authenticated
      } finally {
        setLoading(false); // Set loading to false after the check is done
      }
    };

    checkAuth(); // Validate token

    const bodyElement = document.body;
    const themeClass = `${theme} ${isDarkMode ? `${theme}-dark` : ""}`;
    bodyElement.className = themeClass;
  }, [theme, isDarkMode]);

  // Show a loading spinner or nothing until authentication status is determined
  if (loading) {
    return <div>Loading...</div>; // You can customize this to a loading spinner or any other loading UI
  }

  return (
    <BrowserRouter>
      <div className={`app-container ${theme} ${isDarkMode ? `${theme}-dark` : ""}`}>
        {isTransitioning && <ThemeTransition onAnimationComplete={() => {}} />}
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <SignInForm setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/" />} />
          <Route path="/create" element={<SignUpForm />} />
          <Route path="/reset" element={<ResetPasswordForm />} />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <MainLayout
                  toggleSidebar={toggleSidebar}
                  isSidebarOpen={sidebarOpen}
                  onThemeChange={handleThemeChange}
                  toggleDarkMode={toggleDarkMode}
                  isDarkMode={isDarkMode}
                  setIsAuthenticated={setIsAuthenticated}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
