import React from 'react';

const DateBox = ({ value, onValueChanged, readOnly }) => (
  <input
    type="date"
    value={value}
    onChange={onValueChanged}
    readOnly={readOnly}
  />
);

export default DateBox;
