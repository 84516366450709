import React, { useState } from "react";
import "./Profile.css";
import { FaUserCircle } from "react-icons/fa";

const Profile = () => {
    const [status, setStatus] = useState("Salaried");

    return (
      <div className="profile-container">
        {/* Header Section */}
        <div className="profile-header">
          {/* <div className="profile-image-wrapper">
            <img
              className="profile-image"
              src="https://via.placeholder.com/80"
              alt="Profile"
            />
          </div> */}
          <div className="profile-image-wrapper">
  <FaUserCircle className="profile-icon" />
</div>
          <div className="profile-info">
            <h2 className="profile-name">Guest User</h2>
            <p className="profile-id">
              ID: <span>22</span>
            </p>
            <button className="change-password-btn">Change Password</button>
          </div>
        </div>
  
        {/* Form Section */}
        <div className="details-section">
          <div className="form-row">
            <div className="form-field">
              <label>First Name:</label>
              <input className="profile-input" type="text" value="Guest" readOnly />
            </div>
            <div className="form-field">
              <label>Last Name:</label>
              <input className="profile-input" type="text" value="User" readOnly />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label>Department:</label>
              <select defaultValue="UI/UX">
                <option value="UI/UX">UI/UX</option>
                <option value="Development">Development</option>
              </select>
            </div>
            <div className="form-field">
              <label>Position:</label>
              <select defaultValue="Designer">
                <option value="Designer">Designer</option>
                <option value="Manager">Manager</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label>Hired Date:</label>
              <input className="profile-input" type="date" value="2023-03-03" readOnly />
            </div>
            <div className="form-field">
              <label>Birth Date:</label>
              <input className="profile-input" type="date" value="1980-05-03" readOnly />
            </div>
          </div>
        </div>
  
        {/* Contacts and Address Section */}
        <div className="contacts-address-section">
          <div className="contacts-section">
            <h3>Contacts</h3>
            <p><strong>Phone:</strong> +92 309-1234500</p>
            <p><strong>Email:</strong> guestuser@email.com</p>
            <p><strong>Domain Username:</strong> ealamgroup.pk</p>
            <p>
              <strong>Status:</strong> <span className={`status-dot ${status.toLowerCase()}`}></span>{" "}
              {status}
            </p>
          </div>
          <div className="address-section">
            <h3>Address</h3>
            <p>
              <strong>Street:</strong>42 St
            </p>
            <p>
              <strong>City:</strong> Islamabad
            </p>
            <p>
              <strong>State/Province:</strong> Islamabad
            </p>
            <p>
              <strong>Country:</strong> Pakistan
            </p>
          </div>
        </div>
      </div>
    );
  };

export default Profile;
