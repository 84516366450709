import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import './Input.css';

const ToggleSwitch = ({ label, value, onChanged }) => (
  <FormControlLabel
    control={
      <Switch
        checked={value}
        onChange={onChanged}
        color="primary" // Customize the color of the switch
      />
    }
    label={label} // Display the label next to the switch
  />
);

export default ToggleSwitch;
