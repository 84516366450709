
import './Detail-Input.css';
import React, { useEffect, useRef, useState } from 'react';


const TextArea = ({ value, onChanged, readOnly }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  // Handle focus to show the floating label
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handle blur, and if there's no value, reset the floating label
  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  // Keep the label floated if there is a value
  useEffect(() => {
    if (value) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <div className="detail-textarea-container">
      <textarea
        ref={inputRef}
        className={`detail-Input ${isFocused ? 'focused' : ''}`} // Fix template literal
        value={value || ''}
        onChange={(e) => onChanged(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
      />
      <label className={`Input-label ${isFocused || value ? 'filled' : ''}`}>
      </label>
    </div>
  );
};

export default TextArea;
