const kpiData = [
    { title: 'Opportunities', value: '$8,048,714', change: 20.3 },
    { title: 'Revenue Total', value: '$967,750', change: -14.7 },
    { title: 'Conversion', value: '16%', change: -2.3 },
    { title: 'Leads', value: '51', change: 8.5 },
  ];
  
  const lineData = [
    { month: 'Jan', revenue: 50000 },
    { month: 'Feb', revenue: 80000 },
    { month: 'Mar', revenue: 60000 },
    { month: 'Apr', revenue: 90000 },
    { month: 'May', revenue: 70000 },
    { month: 'Jun', revenue: 85000 },
  ];
  
  const funnelData = [
    { name: 'Sales', value: 3000000, fill: '#8884d8' },
    { name: 'Quotes', value: 2500000, fill: '#82ca9d' },
    { name: 'Opportunities', value: 1600000, fill: '#ffc658' },
    { name: 'Leads', value: 800000, fill: '#ff8042' },
  ];
  
  const pieData = [
    { name: 'Automation', value: 61 },
    { name: 'Monitors', value: 22 },
    { name: 'Projectors', value: 7 },
    { name: 'Televisions', value: 6 },
    { name: 'Video Players', value: 5 },
  ];
  
  const tableData = [
    { state: 'California', sales: '$429,075', percentage: 44 },
    { state: 'Nevada', sales: '$163,225', percentage: 17 },
    { state: 'Wyoming', sales: '$129,200', percentage: 13 },
    { state: 'Arizona', sales: '$90,925', percentage: 9 },
    { state: 'Colorado', sales: '$87,025', percentage: 9 },
    { state: 'Utah', sales: '$68,300', percentage: 7 },
  ];
  
  export { kpiData, lineData, funnelData, pieData, tableData };
  