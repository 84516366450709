import React, { useEffect, useRef, useState } from 'react';
import './Input.css';

const DateTime = ({ 
  label = "Created Date", 
  value, 
  onChange, 
  placeholder = "Select a date and time" 
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (value) {
      setIsFocused(true); // Float the label if there is a value
    }
  }, [value]);

  return (
    <div className="datetime-container">
      <input
        type="Date & Time"
        ref={inputRef}
        className={`Add-Input ${isFocused ? 'focused' : ''}`}
        value={value}
        onChange={onChange} // Ensure this calls onChange from props
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
      <label className={`Input-label ${isFocused || value ? 'filled' : ''}`}>
        {label}
      </label>
    </div>
  );
};

export default DateTime;

