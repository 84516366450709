import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import './Input.css';

const CheckBox = ({ label, value, onChange }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value}
        onChange={onChange} // Use onChange to toggle the checkbox state
        color="primary" // Optional: Adjust checkbox color if needed
      />
    }
    label={label} // Label for the checkbox
  />
);

export default CheckBox;
