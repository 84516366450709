import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoImage from '../images/logo192.png';

      // ====================== Excel Export Function Start ==========================

      const exportToExcel = (tableData, columns, tab) => {
        if (!tableData || tableData.length === 0) {
          alert("No data available to export.");
          return;
        }
    
        // Prepare the data for export
        const visibleColumns = columns.filter((col) => col.visible);
        const headers = visibleColumns.map((col) => col.label);
    
        const exportData = tableData.map((row) => {
          const rowData = {};
          visibleColumns.forEach((col) => {
            rowData[col.label] = row[col.name];
          });
          return rowData;
        });
    
        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData, { header: headers });
    
        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    
        // Export as an Excel file
        XLSX.writeFile(workbook, `exported_data - ${tab}.xlsx`);
      };
    
    
        const exportSelectedToExcel = (tableData, columns, selectedRows, tab) => {
          if (!selectedRows || selectedRows.length === 0) {
            alert("No rows selected for export.");
            return;
          }
      
          // Filter visible columns
          const visibleColumns = columns.filter((col) => col.visible);
          const headers = visibleColumns.map((col) => col.label);
      
          // Prepare selected rows data
          const data = tableData
            .filter((row) => selectedRows.includes(row.id)) // Only include selected rows
            .map((row) =>
              visibleColumns.reduce((acc, col) => {
                acc[col.label] = row[col.name] || ""; // Use header as key and row data as value
                return acc;
              }, {})
            );
      
          if (data.length === 0) {
            alert("No matching data for selected rows.");
            return;
          }
      
          // Create a new worksheet
          const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
      
          // Create a new workbook and append the worksheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Rows");
      
          // Trigger a file download
          XLSX.writeFile(workbook, `selected_rows - ${tab}.xlsx`);
        };
    
      // ====================================================Excel Function End ====================================
      // =======================================Pdf Function Start================================================
    
      const exportToPDF = (tableData, columns, tab) => {
        if (!tableData || tableData.length === 0) {
            alert("No data available to export.");
            return;
        }
    
        const doc = new jsPDF();

    
        // Header section
        const img = new Image();
        img.src = LogoImage;
    
        img.onload = () => {
            // Add logo
            doc.addImage(img, "PNG", 10, 10, 30, 15);
            doc.setFontSize(8);
            doc.text("From Nothing to Something", 10, 30);
            doc.setFontSize(10);
            doc.text(`${tab}`, 13, 35);
    
            // Footer section
            doc.setDrawColor(0);
            doc.setLineWidth(0.5);
            doc.line(10, 280, 200, 280);
            doc.setFontSize(10);
            doc.text("Gulberg Business Centre, Gulberg Green, Islamabad, Pakistan", 10, 285);
            doc.text("+92-312-9393165", 10, 290);
            doc.text("www.ealamgroup.com", 10, 295);
    
            // Prepare table headers and data
            const visibleColumns = columns.filter((col) => col.visible);
            if (visibleColumns.length === 0) {
                alert("No visible columns available.");
                return;
            }
    
            const headers = [visibleColumns.map((col) => col.label)];
            const data = tableData.map((row) =>
                visibleColumns.map((col) => row[col.name] || "")
            );
    
            // Generate table with vertical and horizontal borders
            doc.autoTable({
                head: headers,
                body: data,
                startY: 40,
                margin: { bottom: 40 },
                styles: {
                    fontSize: 10,
                    halign: "center",
                    lineColor: [0, 0, 0], // Set border color to black
                    lineWidth: 0.5, // Border thickness
                },
                headStyles: {
                    fillColor: [200, 200, 200], // Header background color (light gray)
                    textColor: [0, 0, 0],
                    lineWidth: 0.5, // Add borders to headers
                },
                tableLineColor: [0, 0, 0], // Black borders for entire table
                tableLineWidth: 0.5,
                theme: "grid", // Grid theme ensures lines between columns and rows
            });
    
            // Save the PDF
            doc.save(`Exported Data - ${tab}.pdf`);
        };
    };
    
    
    
    
      
        const exportSelectedToPDF = (tableData, columns, selectedRows, tab) => {
          if (!selectedRows || selectedRows.length === 0) {
            alert("No rows selected for export.");
            return;
          }
      
          // Filter visible columns
          const visibleColumns = columns.filter((col) => col.visible);
          const headers = visibleColumns.map((col) => col.label);
      
          // Prepare selected rows data
          const data = tableData
            .filter((row) => selectedRows.includes(row.id)) // Only include selected rows
            .map((row) =>
              visibleColumns.map((col) => row[col.name] || "")
            );
      
          if (data.length === 0) {
            alert("No matching data for selected rows.");
            return;
          }
      
          // Create a new PDF document
          const doc = new jsPDF();
      
          // Add a title
          doc.setFontSize(14);
          doc.text(`Export Selected Rows - ${tab}`, 14, 20);

          // Add the logo image
        const img = new Image();
        img.src = LogoImage;
        img.onload = () => {
            doc.addImage(img, "PNG", 10, 10, 50, 20); // x, y, width, height
    
            // Prepare the data for export
            const visibleColumns = columns.filter((col) => col.visible);
            const headers = visibleColumns.map((col) => col.label);
    
            const data = tableData.map((row) =>
                visibleColumns.map((col) => row[col.name] || "")
            );
    
            // Add a table to the PDF with borders between columns
            doc.autoTable({
                head: [headers],
                body: data,
                startY: 40, // Adjusted to account for the logo
                styles: {
                    fontSize: 10,
                    halign: "left",
                    lineColor: [0, 0, 0], // Line color for borders (black)
                    lineWidth: 0.1, // Thickness of the borders
                },
                headStyles: {
                    fillColor: [200, 200, 200], // Optional header background color
                    textColor: [0, 0, 0], // Text color for headers
                },
                tableLineColor: [0, 0, 0], // Color for the table's borders
                tableLineWidth: 0.1, // Border width between cells
                theme: "striped", // Optional, adds alternating row colors (you can also remove this)
            });
    
            // Save the PDF
            doc.save(`Exported Data - ${tab}.pdf`);
        };
        
        };
    
      // =============================Pdf Function End=======================
      // =============================Csv Function Start======================
    
      const exportToCSV = (tableData, columns, tab) => {
        if (!tableData || tableData.length === 0) {
          alert("No data available to export.");
          return;
        }
    
        // Filter visible columns
        const visibleColumns = columns.filter((col) => col.visible);
        const headers = visibleColumns.map((col) => col.label);
    
        // Prepare all rows data
        const data = tableData.map((row) =>
          visibleColumns.map((col) => {
            const value = row[col.name];
            return typeof value === 'string' ? `"${value}"` : value; // Escape strings with quotes
          })
        );
    
        // Create CSV content
        const csvContent = [headers.join(","), ...data.map((row) => row.join(","))].join("\n");
    
        // Create and download the CSV file
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `exported_data - ${tab}.csv`);
        link.click();
      };
    
        const exportSelectedToCSV = (tableData, columns, selectedRows, tab) => {
          if (!selectedRows || selectedRows.length === 0) {
            alert("No rows selected for export.");
            return;
          }
      
          // Filter visible columns
          const visibleColumns = columns.filter((col) => col.visible);
          const headers = visibleColumns.map((col) => col.label);
      
          // Prepare selected rows data
          const data = tableData
            .filter((row) => selectedRows.includes(row.id)) // Filter selected rows
            .map((row) =>
              visibleColumns.map((col) => {
                const value = row[col.name];
                return typeof value === 'string' ? `"${value}"` : value; // Escape strings with quotes
              })
            );
      
          // Create CSV content
          const csvContent = [headers.join(","), ...data.map((row) => row.join(","))].join("\n");
      
          // Create and download the CSV file
          const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `exported_data - ${tab}.csv`);
          link.click();
        };
    
      // ============================Csv Function End===============================


      export {
        exportToExcel,
        exportSelectedToExcel,
        exportToPDF,
        exportSelectedToPDF,
        exportToCSV,
        exportSelectedToCSV,
      };
      