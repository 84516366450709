import React from 'react';

const ToggleSwitch = ({ value, onValueChanged, readOnly }) => (
  <label className="switch">
    <input
      type="checkbox"
      checked={value}
      onChange={onValueChanged}
      disabled={readOnly}
    />
    <span className="slider"></span>
  </label>
);

export default ToggleSwitch;
