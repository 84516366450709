import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const PasswordBox = ({ label, value, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="password" // Input type for password
    value={value}
    onChange={onChanged}
    InputLabelProps={{ shrink: true }} // Keeps the label visible above the input
    fullWidth // Makes the input full width
    variant="outlined" // You can choose "outlined", "filled", etc.
    className="Add-Input"
  />
);

export default PasswordBox;
