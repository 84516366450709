import React, { useRef } from 'react';

const SignatureBox = ({ value, onValueChanged, readOnly }) => {
  const canvasRef = useRef(null);

  const handleSignature = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    onValueChanged({ value: dataURL });
  };

  return (
    <div>
      <canvas ref={canvasRef} width="400" height="200" onMouseUp={handleSignature} />
      <button onClick={handleSignature} disabled={readOnly}>Save Signature</button>
    </div>
  );
};

export default SignatureBox;
