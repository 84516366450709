import './Input.css';
import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

const TextArea = ({ label, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the text
      document.execCommand('copy'); // Copy the selected text
      alert('Text copied to clipboard!');
    }
  };

  useEffect(() => {
    if (value) {
      setIsFocused(true); // Keep the label floated if there is a value
    }
  }, [value]);

  return (
    <div className="textarea-container">
      <textarea
        ref={inputRef}
        className={`Add-Input ${isFocused ? 'focused' : ''}`}
        value={value}
        onChange={onChange} // Ensure this calls onChange from props
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label className={`Input-label ${isFocused || value ? 'filled' : ''}`}>
        {label}
      </label>
      {value && (
        <button 
        className="copy-button"
        onClick={handleCopy}
        title="Copy">
                                    <HiOutlineClipboardDocumentCheck
                                      style={{ fontSize: "20px", color: "#0f6cbd " }}
                                    />
                                  </button>
      )}
    </div>
  );
};

export default TextArea;