import React, { useRef, useEffect } from 'react';
import { Button, FormLabel } from '@mui/material';
import './Input.css';

const SignatureBox = ({ label, value, onChanged }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set up the canvas for drawing
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.lineCap = 'round';

    let isDrawing = false;

    const startDrawing = (e) => {
      isDrawing = true;
      ctx.beginPath();
      ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    };

    const draw = (e) => {
      if (!isDrawing) return;
      ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      ctx.stroke();
    };

    const stopDrawing = () => {
      isDrawing = false;
      ctx.closePath();
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);

    // Cleanup event listeners on component unmount
    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);
    };
  }, []);

  const handleSaveSignature = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    onChanged({ value: dataURL });
  };

  return (
    <div>
      <FormLabel>{label}</FormLabel> {/* Display the label */}
      <canvas ref={canvasRef} width="400" height="200" style={{ border: '1px solid #ccc' }} />
      <Button variant="contained" color="primary" onClick={handleSaveSignature} style={{ marginTop: '10px' }}>
        Save Signature
      </Button>
    </div>
  );
};

export default SignatureBox;
