import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiLoaderCircle } from "react-icons/bi";
import {
  BsFiletypeCsv,
  BsFiletypePdf,
  BsThreeDots,
  BsThreeDotsVertical,
  BsTrash,
} from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import { IoTrashOutline } from "react-icons/io5";
import { LiaEdit } from "react-icons/lia";
import { LuFileOutput, LuPenLine } from "react-icons/lu";
import { MdCancel, MdKeyboardArrowDown } from "react-icons/md";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import { TbColumnsOff, TbReload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddForm from "../../components/AddForm/AddForm";
import {
  exportSelectedToCSV,
  exportSelectedToExcel,
  exportSelectedToPDF,
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "../../components/list_comp/ExportUtils";
import Pagination from "../../components/list_comp/Pagination";
import { BASE_URL } from "../../url/base";
import InputTypes from "./InputTypes"; // Unified input component
import "./List.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const List = ({ tableName, id, tabConfig, detailData }) => {
  


  const location = useLocation();
  const navigate = useNavigate();
  
  // Instead of reading the query param only once,
  // we'll derive it from location.search and store it in state.
  const [tab, setTab] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("tab");
  });

  // Whenever location.search changes, we parse the new `tab` value
  // and update our state accordingly, causing a re-render.
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newTab = queryParams.get("tab");
    setTab(newTab);
  }, [location.search]);


  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isColumnChooserOpen, setIsColumnChooserOpen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [newRow, setNewRow] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedRows, setEditedRows] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isExportDropdownOpen, setIsExportDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const highlightSearchTerm = (text = "", searchTerm) => {
    if (!searchTerm) return text;

    // Ensure `text` is a string before calling `replace`
    const safeText = String(text);

    const regex = new RegExp(`(${searchTerm})`, "gi");
    return safeText.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    );
  };

  const getHighlightedText = (text) => (
    <span
      dangerouslySetInnerHTML={{
        __html: highlightSearchTerm(text, searchTerm),
      }}
    />
  );

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      if (!tabConfig || !detailData) {
        const response = await fetch(`${BASE_URL}srecord/${tab}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setColumns(
          data.columns.map((col) => ({
            name: col.name,
            label: col.label,
            visible: col.name === "id" ? false : true,
            type: col.type,
            related_to: col.related_to || "",
            id_field: col.id_field || "",
            related_to_label: col.related_to_label || "",
            is_name: col.is_name,
            index: col.index,
            related_to_columns: col.related_to_columns || [],
          }))
        );

        setColumnVisibility(
          data.columns.reduce(
            (acc, col) => ({ ...acc, [col.name]: col.name !== "id" }),
            {}
          )
        );

        setTableData(data.data || []);
      } else {
        const {
          name,
          id_field: idField,
          custom_object: customObject,
        } = tabConfig;
        const dynamicIdValue = detailData[idField];

        if (!tab) {
          const defaultTab = customObject; // Set your default tab name
          setTab(defaultTab);
        }

        if (!dynamicIdValue) {
          console.warn(`No value for id_field "${idField}" in detail data`);
          return;
        }

        try {
          const token = localStorage.getItem("token");
          if (!token) throw new Error("Token not found in localStorage");

          const endpoint = `${BASE_URL}srecord/${tableName}/${dynamicIdValue}/related/${customObject}/${name}`;
          const response = await fetch(endpoint, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          });
          if (!response.ok) throw new Error("Failed to fetch related data");

          const data = await response.json();

          setColumns(
            data.columns.map((col) => ({
              name: col.name,
              label: col.label,
              visible: col.name === "id" ? false : true,
              type: col.type,
              related_to: col.related_to || "",
              id_field: col.id_field || "",
              related_to_label: col.related_to_label || "",
              is_name: col.is_name,
              index: col.index,
              related_to_columns: col.related_to_columns || [],
            }))
          );

          setColumnVisibility(
            data.columns.reduce(
              (acc, col) => ({ ...acc, [col.name]: col.name !== "id" }),
              {}
            )
          );

          setTableData(data.data || []);
        } catch (error) {
          console.error("Error fetching related data:", error);
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshClick = () => {
    setIsRefreshing(true);
    fetchData();
    setTimeout(() => {
      setIsRefreshing(false);
    }, 500);
  };

  useEffect(() => {
    fetchData();
  }, [tableName, id, tabConfig, detailData, tab]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSizeQualifier = (width) => {
    if (width <= 420) return 1;
    if (width <= 768) return 2;
    if (width <= 992) return 4;
    return columns.length;
  };

  const visibleColumnCount = getSizeQualifier(screenWidth);
  const visibleColumns = columns.slice(0, visibleColumnCount);
  const hasHiddenColumns = visibleColumns.length < columns.length;

  const filteredData = tableData.filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (searchTerm) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm("");
  }, [tab]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const handleSelectAllChange = () => {
    if (currentRows.length > 0 && selectedRows.length === currentRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentRows.map((row) => row.id));
    }
  };

  const toggleColumnVisibility = (name) => {
    setColumnVisibility((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleSave = (rowId) => {
    setTableData((prevData) =>
      prevData.map((row) => (row.id === rowId ? { ...row, ...newRow } : row))
    );
    setIsEditing(null);
  };

  const openModal = (rowId) => {
    setSelectedRowId(rowId);
    setOpenDeleteModal(true);
  };

  const closeModal = () => {
    setSelectedRowId(null);
    setOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found in localStorage");

      const response = await fetch(`${BASE_URL}srecord/${tab}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ row_id: selectedRowId }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      setTableData((prevData) =>
        prevData.filter((row) => row.id !== selectedRowId)
      );

      setShowSuccessMessage(true);
      closeModal();
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
  };

  const handleAddNew = () => {
    setIsModalOpen(true);
  };

  const onSave = async (formData) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await fetch(`${BASE_URL}srecord/${tab}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        console.log("ERROR :" + JSON.stringify(response));
        throw new Error("Network response was not ok");
      }

      const newData = await response.json();
      setTableData((prevData) => [...prevData, newData]);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Insert error:", error);
    }
  };

  useEffect(() => {
    setNewRow(columns.reduce((acc, col) => ({ ...acc, [col.name]: "" }), {}));
  }, [columns]);

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevExpanded) =>
      prevExpanded.includes(rowId)
        ? prevExpanded.filter((id) => id !== rowId)
        : [...prevExpanded, rowId]
    );
  };

  const handleEditClick = () => {
    const rowsToEdit = selectedRows.reduce((acc, id) => {
      const row = currentRows.find((row) => row.id === id);
      return { ...acc, [id]: row };
    }, {});
    setEditedRows(rowsToEdit);
    setEditMode(true);
  };

  const handleFieldChange = (rowId, field, value) => {
    setEditedRows((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: value === "" ? null : value,
      },
    }));
  };

  function transformEditedRows(editedRows, columns) {
    // We'll build a new object so we don’t mutate the original
    const newEditedRows = {};
  
    // Go through each row by its key
    for (const rowId in editedRows) {
      // Shallow copy of the row data
      const rowData = { ...editedRows[rowId] };
  
      // Iterate over every column definition
      columns.forEach((col) => {
        // Check if it's a Lookup column
        if (col.type === 'Lookup') {
          // rowData[col.name] might be the related object
          const relatedValue = rowData[col.name];
          // If there's something and it’s an object, replace it with the col.id_field property
          if (
            relatedValue &&
            typeof relatedValue === 'object' &&
            relatedValue[col.id_field]
          ) {
            rowData[col.name] = relatedValue[col.id_field];
          }
        }
      });
  
      // Store the transformed row
      newEditedRows[rowId] = rowData;
    }
  
    return newEditedRows;
  }

  const saveEdits = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found");

      const finalRows = transformEditedRows(editedRows, columns);

      const updates = Object.values(finalRows);
      
      console.log('updates ---- : ',JSON.stringify(finalRows));

      for (const update of updates) {
        const response = await fetch(`${BASE_URL}srecord/${tab}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(update),
        });

        if (!response.ok) throw new Error("Failed to save changes");
      }

      setCurrentRecords((prevRows) =>
        prevRows.map((row) =>
          editedRows[row.id] ? { ...row, ...editedRows[row.id] } : row
        )
      );

      setEditMode(false);
      setEditedRows({});
      setShowSuccessMessage(true);
      alert("Record successfully updated");
    } catch (error) {
      console.error("Error saving edits:", error);
      alert("Failed to save changes. Please try again.");
    }
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const onDelete = async (selectedRowIds) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token not found in localStorage");

      for (const rowId of selectedRowIds) {
        const response = await fetch(`${BASE_URL}srecord/${tab}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ row_id: rowId }),
        });

        if (!response.ok) throw new Error("Network response was not ok");
      }

      setCurrentRecords((prevRows) =>
        prevRows.filter((row) => !selectedRowIds.includes(row.id))
      );
      setSelectedRows([]);
      setShowSuccessMessage(true);
    } catch (error) {
      console.error("Delete error:", error);
    }
  };

  const confirmDelete = async () => {
    await onDelete(selectedRows);
    setDeleteModalOpen(false);
  };

  const toggleExportDropdown = () => {
    setIsExportDropdownOpen(!isExportDropdownOpen);
  };

  // ====================== Export Functions Start ==========================

  // ====================== Export Functions End ==========================

  const truncateText = (text, length = 3) => {
    if (!text) return "";
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  function getValue(rowData, column) {
    // If this column is not a Lookup, just return the basic value
    if (column.type !== 'Lookup') {
      return rowData[column.name];
    }
  
    // For a Lookup type, we expect rowData[column.name] to contain
    // the related object or the related data structure
    const relatedData = rowData[column.name];
  
    if (!relatedData) {
      return ''; // Or return null, or handle however you see fit
    }
  
    // Find the child column that has is_name = true
    const nameColumn = column.related_to_columns?.find((c) => c.is_name);
  
    if (!nameColumn) {
      // If there's no column with is_name = true, fall back to some default
      return relatedData; 
    }
  
    // Return the value from the is_name column (e.g. relatedData["name"])
    return relatedData[nameColumn.name];
  }


  return (
    <div className="maindiv">
      <div className="table-container">
        <div className="table-header">
          <div className="label-div1">
            <Tooltip title={tab} arrow placement="bottom">
              <label className="label">{truncateText(tab)}</label>
            </Tooltip>
          </div>

          <div className="text-right2 three-dots-container">
            <div className="search-container">
              <GoSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-boxx"
              />
            </div>

            {hasHiddenColumns && (
              <IconButton
                onClick={handleToggleDropdown}
                style={{ fontSize: "12px", color: "#0f6cbd " }}
              >
                <BsThreeDots />
              </IconButton>
            )}

            {isDropdownVisible && (
              <div className="dropdown-menu">
                <button onClick={handleAddNew} className="add-btn">
                  <LuPenLine style={{ fontSize: "20px" }} />
                  Add New
                </button>

                {isModalOpen && (
                  <AddForm
                    setIsModalOpen={setIsModalOpen}
                    newRow={newRow}
                    setNewRow={setNewRow}
                    columns={columns}
                    onSave={onSave}
                  />
                )}

                <hr className="dropdown-divider" />

                <div
                  className="dropdown-item"
                  onClick={() => exportToExcel(tableData, columns, tab)}
                >
                  <PiMicrosoftExcelLogoLight className="dropdown-icon" />
                  <span className="dropdown-text">
                    Export All Data to Excel
                  </span>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() =>
                    exportSelectedToExcel(tableData, columns, selectedRows, tab)
                  }
                >
                  <PiMicrosoftExcelLogoLight className="dropdown-icon" />
                  <span className="dropdown-text">
                    Export Selected Rows to Excel
                  </span>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => exportToPDF(tableData, columns, tab)}
                >
                  <BsFiletypePdf className="dropdown-icon" />
                  <span className="dropdown-text">Export All Data to PDF</span>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() =>
                    exportSelectedToPDF(tableData, columns, selectedRows, tab)
                  }
                >
                  <BsFiletypePdf className="dropdown-icon" />
                  <span className="dropdown-text">
                    Export Selected Rows to PDF
                  </span>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => exportToCSV(tableData, columns, tab)}
                >
                  <BsFiletypeCsv className="dropdown-icon" />
                  <span className="dropdown-text">Export All Data to CSV</span>
                </div>
                <div
                  className="dropdown-item"
                  onClick={() =>
                    exportSelectedToCSV(tableData, columns, selectedRows, tab)
                  }
                >
                  <BsFiletypeCsv className="dropdown-icon" />
                  <span className="dropdown-text">
                    Export Selected Rows to CSV
                  </span>
                </div>

                <hr className="dropdown-divider" />

                <div className="dropdown-item" onClick={handleRefreshClick}>
                  {loading ? (
                    <BiLoaderCircle className="dropdown-icon spinning-icon" />
                  ) : (
                    <TbReload className="dropdown-icon" />
                  )}
                  <span className="dropdown-text">Refresh</span>
                </div>

                <div className="column-chooser">
                  <div
                    className="dropdown-item"
                    onClick={() => setIsColumnChooserOpen(true)}
                  >
                    <TbColumnsOff className="dropdown-icon" />
                    <span className="dropdown-text">Columns Chooser</span>
                  </div>
                  <div className="column-chooser-dropdown">
                    <Modal
                      open={isColumnChooserOpen}
                      onClose={() => setIsColumnChooserOpen(false)}
                    >
                      <Box className="column-chooser-modal">
                        <Typography variant="h6">Hide Columns</Typography>
                        {columns.map((column) => (
                          <div key={column.name} className="column-BTN">
                            <input
                              type="checkbox"
                              className="custom-checkbox2"
                              checked={columnVisibility[column.name]}
                              onChange={() =>
                                toggleColumnVisibility(column.name)
                              }
                              style={{
                                width: "17px",
                                height: "17px",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                            />
                            <label>{column.label}</label>
                          </div>
                        ))}
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="label-div">
            <label className="label">{tab}</label>
          </div>

          <div className="opr-div">
            {selectedRows.length >= 2 && (
              <>
                <IconButton onClick={handleEditClick}>
                  <LiaEdit style={{ fontSize: "20px", color: "#0f6cbd" }} />
                </IconButton>
                <IconButton onClick={handleDeleteClick}>
                  <BsTrash style={{ fontSize: "16px", color: "red" }} />
                </IconButton>
              </>
            )}
            <button onClick={handleAddNew} className="add-btn">
              <LuPenLine style={{ fontSize: "20px" }} />
              Add New
            </button>
            {isModalOpen && (
              <AddForm
                setIsModalOpen={setIsModalOpen}
                newRow={newRow}
                setNewRow={setNewRow}
                columns={columns}
                onSave={onSave}
              />
            )}
            <IconButton
              onClick={handleRefreshClick}
              disabled={loading}
              style={{ fontSize: "20px" }}
            >
              {isRefreshing ? (
                <BiLoaderCircle className="spinning-icon" />
              ) : (
                <TbReload />
              )}
            </IconButton>

            <div className="export-pdf-excel">
              <div className="dropdown-item export-dropdown">
                <div className="export-header" onClick={toggleExportDropdown}>
                  <LuFileOutput className="dropdown-icon" />
                  <span className="dropdown-text">Export</span>
                  <MdKeyboardArrowDown className="dropdown-arrow" />
                </div>
                {/* Export options dropdown */}
                {isExportDropdownOpen && (
                  <div className="export-options">
                    <div
                      className="export-option"
                      onClick={() => exportToExcel(tableData, columns, tab)}
                    >
                      <PiMicrosoftExcelLogoLight className="export-option-icon" />
                      <span>Export All Data to Excel</span>
                    </div>
                    <div
                      className="export-option"
                      onClick={() =>
                        exportSelectedToExcel(
                          tableData,
                          columns,
                          selectedRows,
                          tab
                        )
                      }
                    >
                      <PiMicrosoftExcelLogoLight className="export-option-icon" />
                      <span>Export Selected Rows to Excel</span>
                    </div>
                    <div
                      className="export-option"
                      onClick={() => exportToPDF(tableData, columns, tab)}
                    >
                      <BsFiletypePdf className="export-option-icon" />
                      <span>Export All Data to PDF</span>
                    </div>
                    <div
                      className="export-option"
                      onClick={() =>
                        exportSelectedToPDF(
                          tableData,
                          columns,
                          selectedRows,
                          tab
                        )
                      }
                    >
                      <BsFiletypePdf className="export-option-icon" />
                      <span>Export Selected Rows to PDF</span>
                    </div>
                    <div
                      className="export-option"
                      onClick={() => exportToCSV(tableData, columns, tab)}
                    >
                      <BsFiletypeCsv className="export-option-icon" />
                      <span>Export All Data to CSV</span>
                    </div>
                    <div
                      className="export-option"
                      onClick={() =>
                        exportSelectedToCSV(
                          tableData,
                          columns,
                          selectedRows,
                          tab
                        )
                      }
                    >
                      <BsFiletypeCsv className="export-option-icon" />
                      <span>Export Selected Rows to CSV</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="export-pdf-excel-dropdown">
                {columns.map((column) => (
                  <div key={column.name}>
                    <input
                      type="checkbox"
                      checked={columnVisibility[column.name]}
                      onChange={() => toggleColumnVisibility(column.name)}
                    />
                    <label>{column.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="separator" />
            <div className="column-chooser">
              <IconButton
                onClick={() => setIsColumnChooserOpen(true)}
                className="column-chooser-btn"
              >
                <TbColumnsOff style={{ fontSize: "22px" }} />
              </IconButton>
              <div className="column-chooser-dropdown">
                <Modal
                  open={isColumnChooserOpen}
                  onClose={() => setIsColumnChooserOpen(false)}
                >
                  <Box className="column-chooser-modal">
                    <Typography variant="h6">Hide Columns</Typography>
                    {columns.map((column) => (
                      <div key={column.name} className="column-BTN">
                        <input
                          type="checkbox"
                          className="custom-checkbox2"
                          checked={columnVisibility[column.name]}
                          onChange={() => toggleColumnVisibility(column.name)}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                        <label>{column.label}</label>
                      </div>
                    ))}
                  </Box>
                </Modal>
              </div>
            </div>

            <div className="search-container">
              <GoSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-boxx"
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-container">
            <CircularProgress />
          </div>
        ) : (
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-select">
                    <input
                      type="checkbox"
                      className="custom-checkbox1"
                      onChange={handleSelectAllChange}
                      checked={
                        currentRows.length > 0 &&
                        currentRows.every((row) =>
                          selectedRows.includes(row.id)
                        )
                      }
                    />
                  </th>
                  {visibleColumns
                        .map((column) => {
                          if (columnVisibility[column.name]) {
                            return <th key={column.name}>{column.label}</th>;
                          }
                          return null;
                        })}
                                    <th></th>
                </tr>
              </thead>
              <tbody>
                {currentRows.length > 0 ? (
                  currentRows.map((row) => (
                    <React.Fragment key={row.id}>
                      <tr
                        className={
                          selectedRows.includes(row.id) ? "selected" : ""
                        }
                        style={{
                          backgroundColor: selectedRows.includes(row.id)
                            ? "#03a9f412"
                            : "transparent",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <td>
                          <input
                            type="checkbox"
                            className="custom-checkbox1"
                            checked={selectedRows.includes(row.id)}
                            onChange={() => toggleRowSelection(row.id)}
                          />
                        </td>
                        {visibleColumns.map((column) =>
                          columnVisibility[column.name] ? (
                            <td key={column.name}>
                              {editMode && selectedRows.includes(row.id) ? (
                                <InputTypes
                                  type={column.type}
                                  
                                  value={
                                    getValue(
                                      editedRows[row.id] || row, // The row data
                                      column                     // The column definition
                                    )
                                  }

                                  onChange={(e) =>
                                    handleFieldChange(
                                      row.id,
                                      column.name,
                                      e.target?.value ?? e
                                    )
                                  }

                                  onChanged={(newVal) =>
                                      handleFieldChange(
                                        row.id,
                                        column.name,
                                      newVal
                                    )
                                  }

                                  relatedTo={column.related_to}
                                  idField={column.id_field}
                                  label={column.label}
                                />
                              ) :column.is_name ? (
                                <Link
                                  to={`/List_detail/${row.id}/${tab}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#0f6cbd",
                                  }}
                                  onMouseEnter={(e) =>
                                    (e.target.style.color = "#479ef5")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.target.style.color = "#1a73e8")
                                  }
                                >
                                  {row[column.name]}
                                </Link>
                              ) : column.type === "Lookup" ? (
                                column.related_to_columns?.find(
                                  (nestedCol) => nestedCol.is_name
                                )?.name ? (
                                  <Link
                                  to={`/List_detail/${row[column.name]?.id}/${column.related_to}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "#0f6cbd",
                                  }}
                                  onMouseEnter={(e) =>
                                    (e.target.style.color = "#479ef5")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.target.style.color = "#1a73e8")
                                  }
                                  >
                                    {row[column.name]?.[
                                      column.related_to_columns.find(
                                        (nestedCol) => nestedCol.is_name
                                      ).name
                                    ] || ""}
                                  </Link>
                                ) : (
                                  row[column.name] || ""
                                )
                              ) : column.type === "Checkbox" ? (
                                <input
                                  type="checkbox"
                                  className="custom-checkbox1"
                                  readOnly
                                  checked={!!row[column.name]}
                                  style={{ pointerEvents: "none" }}
                                />
                              ) : column.type === "Date & Time" ? (
                                (() => {
                                  const date = new Date(row[column.name]);
                                  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                })()
                              ) : (
                                getHighlightedText(row[column.name] || "")
                              )}
                            </td>
                          ) : null
                        )}
                        <td className="text-right">
                          {hasHiddenColumns && (
                            <IconButton
                              onClick={() => toggleRowExpansion(row.id)}
                              style={{ fontSize: "12px", color: "#0f6cbd " }}
                            >
                              {expandedRows.includes(row.id) ? (
                                <BsThreeDotsVertical />
                              ) : (
                                <BsThreeDots />
                              )}
                            </IconButton>
                          )}
                          <IconButton onClick={() => openModal(row.id)}>
                            <IoTrashOutline
                              style={{ fontSize: "20px", color: "#0f6cbd " }}
                            />
                          </IconButton>
                        </td>
                      </tr>
                      {expandedRows.includes(row.id) && hasHiddenColumns && (
                        <tr>
                          <td colSpan={visibleColumns.length + 2}>
                            <div className="expanded-row">
                              {columns
                                .filter((col) => !visibleColumns.includes(col))
                                .map((col) => (
                                  <div key={col.name} className="expanded-cell">
                                    <strong>{col.label}:</strong>{" "}
                                    {row[col.name]}
                                  </div>
                                ))}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length + 1} className="no-data">
                      No Record Available
                    </td>
                  </tr>
                )}

                <Modal open={openDeleteModal} onClose={closeModal}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 300,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6" component="h2">
                      Confirm Delete
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                      Are you sure you want to delete this item?
                    </Typography>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<MdCancel />}
                        onClick={closeModal}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<AiOutlineCheckCircle />}
                        onClick={handleDelete}
                        color="primary"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                <Snackbar
                  open={showSuccessMessage}
                  autoHideDuration={3000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Deleted Successfully!
                  </Alert>
                </Snackbar>

                <Modal
                  open={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 300,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6">Delete Selected Rows</Typography>
                    <Typography sx={{ mt: 2 }}>
                      Are you sure you want to delete all selected rows?
                    </Typography>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setDeleteModalOpen(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={confirmDelete}
                        color="primary"
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Snackbar
                  open={showSuccessMessage}
                  autoHideDuration={3000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Rows deleted successfully!
                  </Alert>
                </Snackbar>
              </tbody>
            </table>
          </div>
        )}

        <div className="table-footer">
          <Pagination
            rowsPerPage={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            editMode={editMode}
            saveEdits={saveEdits}
            setEditMode={setEditMode}
            currentPage={currentPage}
            totalPages={totalPages}
            filteredDataLength={filteredData.length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};
export default List;
