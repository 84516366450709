import React, { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    Popover,
    Button,
} from '@mui/material';
import { Close as CloseIcon} from '@mui/icons-material';
import { GoSearch } from "react-icons/go";
import { FaChevronDown } from 'react-icons/fa';
import icons from './getIconlookup'; // Adjust the import path if needed

const Lookup = ({ label, value, onChanged }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(
        icons.find(icon => icon.value === value) || null
    );

    // Filter options based on the search term
    const filteredIcons = icons.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelectChange = (option) => {
        setSelectedIcon(option);
        if (typeof onChanged === 'function') {
            onChanged(option.value);
        }
        setAnchorEl(null); // Close the dropdown
    };

    const handleInputClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClearSelection = () => {
        setSelectedIcon(null); // Reset selected icon
        if (typeof onChanged === 'function') {
            onChanged(null); // Call onChanged with null or reset value
        }
        setAnchorEl(null); // Close the dropdown after clearing selection
    };

    const open = Boolean(anchorEl);
    const id = open ? 'icon-popover' : undefined;

    return (
        <div className="detail-lookup-container">
            <div 
                className={`detail-lookup-input ${selectedIcon ? 'filled' : ''}`}
                onClick={handleInputClick}
                style={{ cursor: 'pointer' }}
            >
                <div className="detail-icon-input">
                    {selectedIcon && (
                        <span>{selectedIcon.icon}</span>
                    )}
                    <span className="detail-lookup-label">{label}</span>
                    <span style={{ marginLeft: '12px',marginBottom:'6px'}}>{selectedIcon ? selectedIcon.label : 'Select an option'}</span>
                </div>
                <InputAdornment position="end">
                    {selectedIcon ? (
                        <IconButton onClick={handleClearSelection} aria-label="clear selection">
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <FaChevronDown style={{ cursor: 'pointer' }} />
                    )}
                </InputAdornment>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ zIndex: 9999 }} // Ensure dropdown appears above other elements
            >
                <div className="detail-Dp-container">
                <div className="detail-search-container">
                <GoSearch className="detail-search-icon" />
                        <input
                            autoFocus
                            className="detail-search-input"
                            placeholder="Search"
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>

                    <div className="detail-icon-dropdown">
                        {filteredIcons.length > 0 ? (
                            filteredIcons.map((option, index) => (
                                <div 
                                    key={index} 
                                    className="detail-icon-option" 
                                    onClick={() => handleSelectChange(option)}
                                >
                                    <span style={{ marginRight: '10px' }}>{option.icon}</span>
                                    {option.label}
                                </div>
                            ))
                        ) : (
                            <div className="detail-icon-option disabled">No icons found</div>
                        )}
                    </div>

                    <Button 
                    className="cancel-Sel-btn"
                        fullWidth 
                        variant="outlined" 
                        color="secondary" 
                        onClick={handleClearSelection} 
                        style={{ marginTop: '16px' }}
                    >
                        Clear Selection
                    </Button>
                </div>
            </Popover>
        </div>
    );
};

export default Lookup;
