import React from 'react';
import './Dashboard.css'; // Custom CSS file
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
  FunnelChart,
  Funnel,
  FunnelLabel,
  Cell,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { kpiData, lineData, funnelData, pieData, tableData } from './DashboardData';

const Home = () => {
  return (
    <div className="dashboard">
      {/* Header Section */}
      <div className="header">
        <h1>Dashboard</h1>
        <div className="filters">
          <button>Week</button>
          <button>2 Weeks</button>
          <button>Month</button>
          <button>Year</button>
          <button className="active">All</button>
        </div>
      </div>

      {/* KPI Cards */}
      <div className="kpi-section">
        {kpiData.map((card, index) => (
          <div className="kpi-card" key={index}>
            <h3>{card.title}</h3>
            <h2>{card.value}</h2>
            <span className={card.change > 0 ? 'positive' : 'negative'}>
              {card.change > 0 ? `↑ ${card.change}%` : `↓ ${card.change}%`}
            </span>
          </div>
        ))}
      </div>

      {/* Charts Section */}
      <div className="charts">
        <div className="chart">
          <h2>Revenue</h2>
          <LineChart width={450} height={300} data={lineData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="revenue" stroke="#8884d8" strokeWidth={2} />
          </LineChart>
        </div>

        <div className="chart">
  <h2>Conversion Funnel (All Products)</h2>
  <FunnelChart width={450} height={300}>
    <Tooltip />
    <Legend />
    <Funnel dataKey="value" nameKey="name" data={funnelData} isAnimationActive>
      {funnelData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.fill} />
      ))}
    </Funnel>
  </FunnelChart>
</div>


        <div className="chart">
          <h2>Revenue Snapshot (All Products)</h2>
          <PieChart width={450} height={300}>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#82ca9d"
              label
            />
            <Tooltip />
          </PieChart>
        </div>
      </div>

      {/* Table Section */}
      <div className="table-section">
        <h2>Revenue Analysis</h2>
        <table>
          <thead>
          <tr>
              <th className="tab-th" >State</th>
              <th className="tab-th" >Sales</th>
              <th className="tab-th" >% Sold</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, idx) => (
              <tr key={idx}>
                <td className="tab-td" >{row.state}</td>
                <td className="tab-td" >{row.sales}</td>
                <td className="tab-td" >{row.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export default Home;
