import React from 'react';

const ColorPicker = ({ value, onValueChanged, readOnly }) => (
  <input
    type="color"
    value={value}
    onChange={onValueChanged}
    readOnly={readOnly}
  />
);

export default ColorPicker;
