import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidColor } from "react-icons/bi";
import { HiOutlineBars3 } from "react-icons/hi2";
import { WiDaySunny } from "react-icons/wi";
import { PiMoonThin } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import { TbReload } from "react-icons/tb";
import {
  IconButton,
  Box,
  Modal,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import { BiLoaderCircle } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = ({
  toggleSidebar,
  onThemeChange,
  toggleDarkMode,
  isDarkMode,
  setIsAuthenticated,
  onRefresh,
  userName,
  userImage,
}) => {
  const [showThemePicker, setShowThemePicker] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const themes = [
    "Pinkish",
    "maroonish-reddish",
    "purple",
    "LemonChiffon",
    "yellowish",
    "orangish",
    "brownish",
    "dark-greenish",
    "cyan-teal",
    "skybluish",
    "gray-backwhite",
    "metallic1",
    "metallic2",
  ];

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  const handleProfileClick = () => {
    navigate("/Profile");
  };

  // Trigger refresh and manage loading state
  const handleRefreshClick = () => {
    setIsRefreshing(true); // Start loading
    onRefresh(); // Trigger the refresh function
    setTimeout(() => {
      setIsRefreshing(false); // Stop loading after refresh completes
    }, 3500); // Match duration with refresh logic
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <header className="header">
        <IconButton onClick={toggleSidebar} className="head-icon">
          <HiOutlineBars3 />
        </IconButton>
        <h1 className="header-title">
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            eAlam Group
          </Link>
        </h1>
        <div className="header-right">
          {/* Refresh Button */}
          <Tooltip title="Refresh Data" arrow placement="bottom">
            <IconButton
              onClick={handleRefreshClick} // Trigger the refresh function with loading state
              className="header-icon"
            >
              {isRefreshing ? (
                <BiLoaderCircle className="spinning-icon" /> // Spinning loader icon
              ) : (
                <TbReload />
              )}
            </IconButton>
          </Tooltip>
          {/* Theme Picker Button */}
          {/* <div className="theme">
            <IconButton
              onClick={() => setShowThemePicker(true)}
              className="header-icon"
            >
              <BiSolidColor style={{ fontSize: "22px" }} />
            </IconButton>
            
            <Modal
              open={showThemePicker}
              onClose={() => setShowThemePicker(false)}
            >
              <Box className="theme-modal">
                <Typography variant="h6">Select Theme</Typography>
                <div className="theme-picker-dropdown">
                  <div className="theme-grid">
                    {themes.map((theme) => (
                      <div
                        key={theme}
                        className={`theme-circle ${theme} ${isDarkMode ? `${theme}-dark` : ""}`}
                        onClick={() => {
                          onThemeChange(theme);
                          setShowThemePicker(false);
                        }}
                        title={theme}
                      ></div>
                    ))}
                  </div>
                </div>
              </Box>
            </Modal>
          </div> */}
          {/* Dark/Light Mode Toggle */}
          <IconButton onClick={toggleDarkMode} className="header-icon">
            {isDarkMode ? (
              <WiDaySunny style={{ fontSize: "22px" }} />
            ) : (
              <PiMoonThin style={{ fontSize: "19px" }} />
            )}
          </IconButton>
          {/* User Profile Dropdown */}
          <Tooltip title="User Menu" arrow placement="bottom">
            <IconButton onClick={handleMenuOpen} className="header-icon">
              <Avatar
                src={userImage || ""}
                alt="User Image"
                style={{ width: 30, height: 30 }}
              >
                {!userImage && userName ? (
                  userName.charAt(0).toUpperCase()
                ) : (
                  <FaRegUser />
                )}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            className="user-dropdown"
          >
            <MenuItem onClick={handleProfileClick}>
              {userName || "Profile"}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <GiExitDoor style={{ marginRight: "10px" }} />
              Logout
            </MenuItem>
          </Menu>
        </div>
      </header>
    </div>
  );
};

export default Header;
