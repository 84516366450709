import React from 'react';

const FileUpload = ({ value, onValueChanged, readOnly }) => (
  <input
    type="file"
    onChange={onValueChanged}
    disabled={readOnly}
  />
);

export default FileUpload;
