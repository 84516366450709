import React from "react";
import { IoTrashOutline } from "react-icons/io5";
import { TbColumnsOff } from "react-icons/tb";
import { LuFileOutput, LuPenLine } from "react-icons/lu";
import { HiOutlineInboxIn } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaChartBar } from "react-icons/fa";
import { MdAppSettingsAlt } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { GrUserSettings } from "react-icons/gr";
import { AiOutlineAppstore } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { AiOutlineAudit } from "react-icons/ai";
import { AiOutlineBank } from "react-icons/ai";
import { AiOutlineCodepen } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineHarmonyOS } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineHdd } from "react-icons/ai";
import { AiOutlineHolder } from "react-icons/ai";
import { AiOutlineIdcard } from "react-icons/ai";
import { AiOutlineHourglass } from "react-icons/ai";
import { AiOutlineShop } from "react-icons/ai";
import { AiOutlineShopping } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineTruck } from "react-icons/ai";
import { AiOutlineWallet } from "react-icons/ai";
import { AiOutlineProject } from "react-icons/ai";
import { AiOutlineGift } from "react-icons/ai";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsBackpack } from "react-icons/bs";
import { BsBackpack2 } from "react-icons/bs";
import { BsBackpack3 } from "react-icons/bs";
import { BsBackpack4 } from "react-icons/bs";
import { BsBagHeart } from "react-icons/bs";
import { BsBagPlus } from "react-icons/bs";
import { BsBarChart } from "react-icons/bs";
import { BsBalloonHeart } from "react-icons/bs";
import { BsBarChartSteps } from "react-icons/bs";
import { BsBoxSeamFill } from "react-icons/bs";
import { BsBoxSeam } from "react-icons/bs";
import { BsBriefcase } from "react-icons/bs";
import { BsBuildings } from "react-icons/bs";
import { BsBuilding } from "react-icons/bs";
import { BsCalendar } from "react-icons/bs";
import { BsCalendar2 } from "react-icons/bs";
import { BsCalendar2Check } from "react-icons/bs";
import { BsCalendar2Event } from "react-icons/bs";
import { BsCalendarWeek } from "react-icons/bs";
import { BsCart2 } from "react-icons/bs";
import { BsCreditCard2Front } from "react-icons/bs";
import { BsFlower1 } from "react-icons/bs";
import { BsFlower2 } from "react-icons/bs";
import { BsFolder2Open } from "react-icons/bs";
import { BsGraphUp } from "react-icons/bs";
import { BsGraphDown } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import { BsHospital } from "react-icons/bs";
import { BsHouseHeart } from "react-icons/bs";
import { BsHouseDoor } from "react-icons/bs";
import { BsHouseLock } from "react-icons/bs";
import { BsHouses } from "react-icons/bs";
import { BsHouseSlash } from "react-icons/bs";
import { BsHouseUp } from "react-icons/bs";
import { BsJournals } from "react-icons/bs";
import { BsKanban } from "react-icons/bs";
import { BsLamp } from "react-icons/bs";
import { BsMoisture } from "react-icons/bs";
import { BsMortarboard } from "react-icons/bs";
import { BsWrenchAdjustable } from "react-icons/bs";
import { BsWrench } from "react-icons/bs";
import { BsViewStacked } from "react-icons/bs";
import { BsToggles } from "react-icons/bs";
import { BsToggles2 } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsTable } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { BsStars } from "react-icons/bs";
import { BsSnow2 } from "react-icons/bs";
import { BsSlack } from "react-icons/bs";
import { BsShop } from "react-icons/bs";
import { BsReceipt } from "react-icons/bs";
import { BsReceiptCutoff } from "react-icons/bs";
import { BiArchive } from "react-icons/bi";
import { BiBarChart } from "react-icons/bi";
import { BiBarChartAlt } from "react-icons/bi";
import { BiBarChartAlt2 } from "react-icons/bi";
import { BiBarChartSquare } from "react-icons/bi";
import { BiBriefcase } from "react-icons/bi";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { BiBriefcaseAlt } from "react-icons/bi";
import { BiBuilding } from "react-icons/bi";
import { BiBuildingHouse } from "react-icons/bi";
import { BiBuildings } from "react-icons/bi";
import { BiCabinet } from "react-icons/bi";
import { BiCalendar } from "react-icons/bi";
import { BiCalendarEvent } from "react-icons/bi";
import { BiCartAlt } from "react-icons/bi";
import { BiCategoryAlt } from "react-icons/bi";
import { BiCategory } from "react-icons/bi";
import { BiCart } from "react-icons/bi";
import { BiDollar } from "react-icons/bi";
import { BiDollarCircle } from "react-icons/bi";
import { BiEuro } from "react-icons/bi";
import { BiNotepad } from "react-icons/bi";
import { BiNoSignal } from "react-icons/bi";
import { BiObjectsHorizontalLeft } from "react-icons/bi";
import { BiObjectsVerticalBottom } from "react-icons/bi";
import { BiOutline } from "react-icons/bi";
import { BiPackage } from "react-icons/bi";
import { BiRuble } from "react-icons/bi";
import { BiRupee } from "react-icons/bi";
import { BiSignal3 } from "react-icons/bi";
import { BiSignal4 } from "react-icons/bi";
import { BiSignal5 } from "react-icons/bi";
import { BiSignal2 } from "react-icons/bi";
import { BiSpa } from "react-icons/bi";
import { CiAlignBottom } from "react-icons/ci";
import { CiAlignLeft } from "react-icons/ci";
import { CiBadgeDollar } from "react-icons/ci";
import { CiBag1 } from "react-icons/ci";
import { CiMicrophoneOff } from "react-icons/ci";
import { CiForkAndKnife } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { CiStethoscope } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";
import { CgDesignmodo } from "react-icons/cg";
import { CgShapeCircle } from "react-icons/cg";
import { FiBarChart2 } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiToggleRight } from "react-icons/fi";
import { FiToggleLeft } from "react-icons/fi";
import { TfiBarChart } from "react-icons/tfi";
import { TfiLayoutGrid2Thumb } from "react-icons/tfi";
import { TfiLayout } from "react-icons/tfi";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { TbCannabis } from "react-icons/tb";
import { TbChecks } from "react-icons/tb";
import { TbChecklist } from "react-icons/tb";
import { TbGridDots } from "react-icons/tb";
import { TbLayout } from "react-icons/tb";
import { TbLayoutBoard } from "react-icons/tb";
import { TbLayoutBoardSplit } from "react-icons/tb";
import { SiWolfram } from "react-icons/si";
import { SiVowpalwabbit } from "react-icons/si";
import { SiTga } from "react-icons/si";
import { SiSoundcharts } from "react-icons/si";
import { SiRubysinatra } from "react-icons/si";
import { SiParsedotly } from "react-icons/si";
import { SiJabber } from "react-icons/si";
import { SiGoogleanalytics } from "react-icons/si";
import { SiClyp } from "react-icons/si";
import { RiAppsLine } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { RiBarChartGroupedFill } from "react-icons/ri";
import { RiBuilding2Line } from "react-icons/ri";
import { RiBuildingLine } from "react-icons/ri";
import { RiGolfBallLine } from "react-icons/ri";
import { RiHeart2Line } from "react-icons/ri";
import { RiPantoneLine } from "react-icons/ri";
import { RiScissors2Line } from "react-icons/ri";
import { RiScales3Line } from "react-icons/ri";
import { RiToothLine } from "react-icons/ri";
import { RxCalendar } from "react-icons/rx";
import { RxCardStackPlus } from "react-icons/rx";
import { RxCodesandboxLogo } from "react-icons/rx";
import { RxComponent1 } from "react-icons/rx";
import { RxCube } from "react-icons/rx";
import { PiBedDuotone } from "react-icons/pi";
import { PiBeachBallDuotone } from "react-icons/pi";
import { PiBell } from "react-icons/pi";
import { PiBicycleLight } from "react-icons/pi";
import { PiBirdLight } from "react-icons/pi";
import { PiBoneLight } from "react-icons/pi";
import { PiCarProfileLight } from "react-icons/pi";
import { PiCarLight } from "react-icons/pi";
import { PiCardholder } from "react-icons/pi";
import { PiCoffeeLight } from "react-icons/pi";
import { PiCookingPotLight } from "react-icons/pi";
import { PiCouchLight } from "react-icons/pi";
import { PiCrown } from "react-icons/pi";
import { PiCrownSimpleLight } from "react-icons/pi";
import { PiCurrencyGbpLight } from "react-icons/pi";
import { PiCurrencyJpyLight } from "react-icons/pi";
import { PiCurrencyKztLight } from "react-icons/pi";
import { PiCurrencyNgnLight } from "react-icons/pi";
import { PiCurrencyRubLight } from "react-icons/pi";
import { PiDeskLight } from "react-icons/pi";
import { PiDesktopTowerLight } from "react-icons/pi";
import { PiEnvelopeSimpleThin } from "react-icons/pi";
import { PiEnvelopeSimpleOpenThin } from "react-icons/pi";
import { PiEyesFill } from "react-icons/pi";
import { PiGavelLight } from "react-icons/pi";
import { PiGlobeHemisphereWestDuotone } from "react-icons/pi";
import { PiGraduationCapLight } from "react-icons/pi";
import { PiGuitarLight } from "react-icons/pi";
import { PiHamburgerLight } from "react-icons/pi";
import { PiHairDryerLight } from "react-icons/pi";
import { PiHandPalmLight } from "react-icons/pi";
import { PiHandbagLight } from "react-icons/pi";
import { PiHeadsetLight } from "react-icons/pi";
import { PiIslandLight } from "react-icons/pi";
import { PiJeepLight } from "react-icons/pi";
import { PiLightbulbFilamentThin } from "react-icons/pi";
import { PiMartiniLight } from "react-icons/pi";
import { PiMaskSadLight } from "react-icons/pi";
import { PiMegaphoneLight } from "react-icons/pi";
import { PiMoneyWavyLight } from "react-icons/pi";
import { PiMoneyLight } from "react-icons/pi";
import { PiMoped } from "react-icons/pi";
import { PiMopedFront } from "react-icons/pi";
import { PiMotorcycleFill } from "react-icons/pi";
import { PiMusicNoteLight } from "react-icons/pi";
import { PiParkLight } from "react-icons/pi";
import { PiPizzaLight } from "react-icons/pi";
import { PiPlantLight } from "react-icons/pi";
import { PiPrinterLight } from "react-icons/pi";
import { PiProjectorScreen } from "react-icons/pi";
import { PiProjectorScreenChartLight } from "react-icons/pi";
import { PiScissorsFill } from "react-icons/pi";
import { PiScooter } from "react-icons/pi";
import { PiScrewdriverLight } from "react-icons/pi";
import { PiScrollLight } from "react-icons/pi";
import { PiSecurityCameraLight } from "react-icons/pi";
import { PiShippingContainerDuotone } from "react-icons/pi";
import { PiShoppingCartSimple } from "react-icons/pi";
import { PiShovelLight } from "react-icons/pi";
import { PiSketchLogoLight } from "react-icons/pi";
import { PiSpeedometerLight } from "react-icons/pi";
import { PiSphereLight } from "react-icons/pi";
import { PiStudentLight } from "react-icons/pi";
import { PiSyringeLight } from "react-icons/pi";
import { PiTennisBallFill } from "react-icons/pi";
import { PiTentLight } from "react-icons/pi";
import { PiTestTubeLight } from "react-icons/pi";
import { PiThermometerLight } from "react-icons/pi";
import { PiTipiFill } from "react-icons/pi";
import { PiTractorLight } from "react-icons/pi";
import { PiTrashLight } from "react-icons/pi";
import { PiTrayLight } from "react-icons/pi";
import { PiTrashSimpleThin } from "react-icons/pi";
import { PiTreeEvergreenThin } from "react-icons/pi";
import { PiTreePalmLight } from "react-icons/pi";
import { PiTreeLight } from "react-icons/pi";
import { PiTrolley } from "react-icons/pi";
import { PiTruckLight } from "react-icons/pi";
import { PiTruckTrailerLight } from "react-icons/pi";
import { PiUmbrellaThin } from "react-icons/pi";
import { PiVanLight } from "react-icons/pi";
import { PiVaultLight } from "react-icons/pi";
import { PiWalletLight } from "react-icons/pi";
import { PiWarehouseLight } from "react-icons/pi";
import { PiWallLight } from "react-icons/pi";
import { PiWashingMachineLight } from "react-icons/pi";
import { PiWheelchairLight } from "react-icons/pi";
import { PiWheelchairMotionLight } from "react-icons/pi";
import { PiWineLight } from "react-icons/pi";
import { PiWindLight } from "react-icons/pi";
import { MdBatteryCharging60 } from "react-icons/md";
import { MdCoffeeMaker } from "react-icons/md";
import { MdDirectionsBike } from "react-icons/md";
import { MdElectricBike } from "react-icons/md";
import { MdElectricCar } from "react-icons/md";
import { MdElectricScooter } from "react-icons/md";
import { MdElectricBolt } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineBlender } from "react-icons/md";
import { MdOutlineBrush } from "react-icons/md";
import { MdOutlineCached } from "react-icons/md";
import { MdOutlineCheck } from "react-icons/md";
import { MdOutlineClear } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import { MdOutlineGrass } from "react-icons/md";
import { MdOutlineTwoWheeler } from "react-icons/md";
import { MdQuestionMark } from "react-icons/md";
import { MdSailing } from "react-icons/md";
import { MdSatelliteAlt } from "react-icons/md";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";
import { MdSignalWifi3Bar } from "react-icons/md";
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md";
import { MdSignalWifiStatusbarNotConnected } from "react-icons/md";
import { MdSwitchAccessShortcutAdd } from "react-icons/md";
import { MdSync } from "react-icons/md";
import { MdSyncDisabled } from "react-icons/md";
import { MdSyncLock } from "react-icons/md";
import { MdSyncProblem } from "react-icons/md";
import { MdTerrain } from "react-icons/md";
import { MdThumbUpOffAlt } from "react-icons/md";
import { MdThumbDownOffAlt } from "react-icons/md";
import { MdTimeToLeave } from "react-icons/md";
import { MdWindPower } from "react-icons/md";
import { LuAirVent } from "react-icons/lu";
import { LuCake } from "react-icons/lu";
import { LuJapaneseYen } from "react-icons/lu";
import { LuPen } from "react-icons/lu";
import { LuPencil } from "react-icons/lu";
import { ImPencil2 } from "react-icons/im";
import { HiListBullet } from "react-icons/hi2";
import { HiLockClosed } from "react-icons/hi2";
import { HiLockOpen } from "react-icons/hi2";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { HiOutlineArrowDownTray } from "react-icons/hi2";
import { HiOutlinePresentationChartBar } from "react-icons/hi2";
import { ImBarcode } from "react-icons/im";
import { ImMan } from "react-icons/im";
import { ImManWoman } from "react-icons/im";
import { LiaAmbulanceSolid } from "react-icons/lia";
import { LiaCashRegisterSolid } from "react-icons/lia";
import { LiaCentos } from "react-icons/lia";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { LiaPenAltSolid } from "react-icons/lia";
import { LiaSignatureSolid } from "react-icons/lia";
import { LiaSpiderSolid } from "react-icons/lia";
import { LiaTheaterMasksSolid } from "react-icons/lia";
import { LiaWhmcs } from "react-icons/lia";
import { LiaYoutube } from "react-icons/lia";
import { GoArrowBoth } from "react-icons/go";
import { GoArrowSwitch } from "react-icons/go";
import { GoArrowDown } from "react-icons/go";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowDownRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import { GoArrowUp } from "react-icons/go";
import { GoArrowUpLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import { GoBeaker } from "react-icons/go";
import { GoBook } from "react-icons/go";
import { GoBookmark } from "react-icons/go";
import { GoBellSlash } from "react-icons/go";
import { GoBell } from "react-icons/go";
import { GoBookmarkSlash } from "react-icons/go";
import { GoCheckCircle } from "react-icons/go";
import { GoCircle } from "react-icons/go";
import { GoClock } from "react-icons/go";
import { GoCodeOfConduct } from "react-icons/go";
import { GoCommentDiscussion } from "react-icons/go";
import { GoPerson } from "react-icons/go";
import { GoThumbsup } from "react-icons/go";
import { GoThumbsdown } from "react-icons/go";
import { GoSun } from "react-icons/go";
import { GoTrophy } from "react-icons/go";
import { GoSquirrel } from "react-icons/go";
import { GoShieldCheck } from "react-icons/go";
import { GoShieldLock } from "react-icons/go";
import { GoCrossReference } from "react-icons/go";
import { GoFileMedia } from "react-icons/go";
import { GoKey } from "react-icons/go";
import { GoLaw } from "react-icons/go";
import { GoLocation } from "react-icons/go";
import { GoMilestone } from "react-icons/go";
import { CiCoffeeCup } from "react-icons/ci";
import { CiSaveUp2 } from "react-icons/ci";
import { IoCloudUploadOutline } from "react-icons/io5";
import { GiSuperMushroom } from "react-icons/gi";
import { BsUpcScan } from "react-icons/bs";
import { BsJournalArrowUp } from "react-icons/bs";
import { BsUpc } from "react-icons/bs";
import { BiVideoRecording } from "react-icons/bi";
import { RiRecordMailLine } from "react-icons/ri";
import { IoMdRecording } from "react-icons/io";
import { IoIosPhonePortrait } from "react-icons/io";
import { IoIosPhoneLandscape } from "react-icons/io";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { IoPhoneLandscapeOutline } from "react-icons/io5";
import { CiMicrophoneOn } from "react-icons/ci";
import { FaHeadphonesSimple } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { GiMicrophone } from "react-icons/gi";
import { TfiFaceSad } from "react-icons/tfi";
import { TfiFaceSmile } from "react-icons/tfi";
import { GrFacebookOption } from "react-icons/gr";
import { MdCurrencyFranc } from "react-icons/md";
import { TbCurrencyDinar } from "react-icons/tb";
import { TbCurrencyCent } from "react-icons/tb";
import { IoCarSport } from "react-icons/io5";
import { PiFadersHorizontalLight } from "react-icons/pi";
import { PiFadersThin } from "react-icons/pi";
import { PiFileRsLight } from "react-icons/pi";
import { LiaSpinnerSolid } from "react-icons/lia";
import { CiBank } from "react-icons/ci";
import { GiBank } from "react-icons/gi";
import { BsBank2 } from "react-icons/bs";
import { PiBank } from "react-icons/pi";
import { GiWingCloak } from "react-icons/gi";



const icons = [
  { value: 'TbColumnsOff', icon: <TbColumnsOff />, label: 'ColumnsOff' },
  { value: 'LuFileOutput', icon: <LuFileOutput />, label: 'FileOutput' },
  { value: 'IoTrashOutline', icon: <IoTrashOutline />, label: 'TrashOutline' },
  { value: 'LuPenLine', icon: <LuPenLine />, label: 'PenLine' },
  { value: 'FaChartBar', icon: <FaChartBar />, label: 'ChartBar' },
  { value: 'HiOutlineInboxIn', icon: <HiOutlineInboxIn />, label: 'InboxIn' },
  { value: 'AiOutlineSetting', icon: <AiOutlineSetting />, label: 'Setting' },
  { value: 'MdAppSettingsAlt', icon: <MdAppSettingsAlt />, label: 'AppSettingsAlt' },
  { value: 'MdOutlineAdminPanelSettings', icon: <MdOutlineAdminPanelSettings />, label: 'AdminPanelSettings' },
  { value: 'MdOutlinePowerSettingsNew', icon: <MdOutlinePowerSettingsNew />, label: 'PowerSettingsNew' },
  { value: 'MdOutlineDisplaySettings', icon: <MdOutlineDisplaySettings />, label: 'DisplaySettings' },
  { value: 'GrUserSettings', icon: <GrUserSettings />, label: 'UserSettings' },
  { value: 'AiOutlineAppstore', icon: <AiOutlineAppstore />, label: 'Appstore' },
  { value: 'AiOutlineAppstoreAdd', icon: <AiOutlineAppstoreAdd />, label: 'AppstoreAdd' },
  { value: 'AiOutlineAudit', icon: <AiOutlineAudit />, label: 'Audit' },
  { value: 'AiOutlineBank', icon: <AiOutlineBank />, label: 'Bank' },
  { value: 'AiOutlineCodepen', icon: <AiOutlineCodepen />, label: 'Codepen' },
  { value: 'AiOutlineHome', icon: <AiOutlineHome />, label: 'Home' },
  { value: 'AiOutlineHarmonyOS', icon: <AiOutlineHarmonyOS />, label: 'HarmonyOS' },
  { value: 'AiOutlineHeart', icon: <AiOutlineHeart />, label: 'Heart' },
  { value: 'AiOutlineHdd', icon: <AiOutlineHdd />, label: 'Hdd' },
  { value: 'AiOutlineHolder', icon: <AiOutlineHolder />, label: 'Holder' },
  { value: 'AiOutlineIdcard', icon: <AiOutlineIdcard />, label: 'Idcard' },
  { value: 'AiOutlineHourglass', icon: <AiOutlineHourglass />, label: 'Hourglass' },
  { value: 'AiOutlineShop', icon: <AiOutlineShop />, label: 'Shop' },
  { value: 'AiOutlineShopping', icon: <AiOutlineShopping />, label: 'Shopping' },
  { value: 'AiOutlineShoppingCart', icon: <AiOutlineShoppingCart />, label: 'ShoppingCart' },
  { value: 'AiOutlineTruck', icon: <AiOutlineTruck />, label: 'Truck' },
  { value: 'AiOutlineWallet', icon: <AiOutlineWallet />, label: 'Wallet' },
  { value: 'AiOutlineProject', icon: <AiOutlineProject />, label: 'Project' },
  { value: 'AiOutlineGift', icon: <AiOutlineGift />, label: 'Gift' },
  { value: 'AiOutlineCalendar', icon: <AiOutlineCalendar />, label: 'Calendar' },
  { value: 'BsBackpack', icon: <BsBackpack />, label: 'Backpack' },
  { value: 'BsBackpack2', icon: <BsBackpack2 />, label: 'Backpack2' },
  { value: 'BsBackpack3', icon: <BsBackpack3 />, label: 'Backpack3' },
  { value: 'BsBackpack4', icon: <BsBackpack4 />, label: 'Backpack4' },
  { value: 'BsBagHeart', icon: <BsBagHeart />, label: 'BagHeart' },
  { value: 'BsBagPlus', icon: <BsBagPlus />, label: 'BagPlus' },
  { value: 'BsBarChart', icon: <BsBarChart />, label: 'BarChart' },
  { value: 'BsBalloonHeart', icon: <BsBalloonHeart />, label: 'BalloonHeart' },
  { value: 'BsBarChartSteps', icon: <BsBarChartSteps />, label: 'BarChartSteps' },
  { value: 'BsBoxSeamFill', icon: <BsBoxSeamFill />, label: 'BoxSeamFill' },
  { value: 'BsBoxSeam', icon: <BsBoxSeam />, label: 'BoxSeam' },
  { value: 'BsBriefcase', icon: <BsBriefcase />, label: 'Briefcase' },
  { value: 'BsBuildings', icon: <BsBuildings />, label: 'Buildings' },
  { value: 'BsBuilding', icon: <BsBuilding />, label: 'Building' },
  { value: 'BsCalendar', icon: <BsCalendar />, label: 'Calendar' },
  { value: 'BsCalendar2', icon: <BsCalendar2 />, label: 'Calendar2' },
  { value: 'BsCalendar2Check', icon: <BsCalendar2Check />, label: 'Calendar2Check' },
  { value: 'BsCalendar2Event', icon: <BsCalendar2Event />, label: 'Calendar2Event' },
  { value: 'BsCalendarWeek', icon: <BsCalendarWeek />, label: 'CalendarWeek' },
  { value: 'BsCart2', icon: <BsCart2 />, label: 'Cart2' },
  { value: 'BsCreditCard2Front', icon: <BsCreditCard2Front />, label: 'CreditCard2Front' },
  { value: 'BsFlower1', icon: <BsFlower1 />, label: 'Flower1' },
  { value: 'BsFlower2', icon: <BsFlower2 />, label: 'Flower2' },
  { value: 'BsFolder2Open', icon: <BsFolder2Open />, label: 'Folder2Open' },
  { value: 'BsGraphUp', icon: <BsGraphUp />, label: 'GraphUp' },
  { value: 'BsGraphDown', icon: <BsGraphDown />, label: 'GraphDown' },
  { value: 'BsHeart', icon: <BsHeart />, label: 'Heart' },
  { value: 'BsHospital', icon: <BsHospital />, label: 'Hospital' },
  { value: 'BsHouseHeart', icon: <BsHouseHeart />, label: 'HouseHeart' },
  { value: 'BsHouseDoor', icon: <BsHouseDoor />, label: 'HouseDoor' },
  { value: 'BsHouseLock', icon: <BsHouseLock />, label: 'HouseLock' },
  { value: 'BsHouses', icon: <BsHouses />, label: 'Houses' },
  { value: 'BsHouseSlash', icon: <BsHouseSlash />, label: 'HouseSlash' },
  { value: 'BsHouseUp', icon: <BsHouseUp />, label: 'HouseUp' },
  { value: 'BsJournals', icon: <BsJournals />, label: 'Journals' },
  { value: 'BsKanban', icon: <BsKanban />, label: 'Kanban' },
  { value: 'BsLamp', icon: <BsLamp />, label: 'Lamp' },
  { value: 'BsMoisture', icon: <BsMoisture />, label: 'Moisture' },
  { value: 'BsMortarboard', icon: <BsMortarboard />, label: 'Mortarboard' },
  { value: 'BsWrenchAdjustable', icon: <BsWrenchAdjustable />, label: 'WrenchAdjustable' },
  { value: 'BsWrench', icon: <BsWrench />, label: 'Wrench' },
  { value: 'BsViewStacked', icon: <BsViewStacked />, label: 'ViewStacked' },
  { value: 'BsToggles', icon: <BsToggles />, label: 'Toggles' },
  { value: 'BsToggles2', icon: <BsToggles2 />, label: 'Toggles2' },
  { value: 'BsThreeDots', icon: <BsThreeDots />, label: 'ThreeDots' },
  { value: 'BsThreeDotsVertical', icon: <BsThreeDotsVertical />, label: 'ThreeDotsVertical' },
  { value: 'BsTable', icon: <BsTable />, label: 'Table' },
  { value: 'BsStar', icon: <BsStar />, label: 'Star' },
  { value: 'BsStars', icon: <BsStars />, label: 'Stars' },
  { value: 'BsSnow2', icon: <BsSnow2 />, label: 'Snow2' },
  { value: 'BsSlack', icon: <BsSlack />, label: 'Slack' },
  { value: 'BsShop', icon: <BsShop />, label: 'Shop' },
  { value: 'BsReceipt', icon: <BsReceipt />, label: 'Receipt' },
  { value: 'BsReceiptCutoff', icon: <BsReceiptCutoff />, label: 'ReceiptCutoff' },
  { value: 'BiArchive', icon: <BiArchive />, label: 'Archive' },
  { value: 'BiBarChart', icon: <BiBarChart />, label: 'BarChart' },
  { value: 'BiBarChartAlt', icon: <BiBarChartAlt />, label: 'BarChartAlt' },
  { value: 'BiBarChartAlt2', icon: <BiBarChartAlt2 />, label: 'BarChartAlt2' },
  { value: 'BiBarChartSquare', icon: <BiBarChartSquare />, label: 'BarChartSquare' },
  { value: 'BiBriefcase', icon: <BiBriefcase />, label: 'Briefcase' },
  { value: 'BiBriefcaseAlt2', icon: <BiBriefcaseAlt2 />, label: 'BriefcaseAlt2' },
  { value: 'BiBriefcaseAlt', icon: <BiBriefcaseAlt />, label: 'BriefcaseAlt' },
  { value: 'BiBuilding', icon: <BiBuilding />, label: 'Building' },
  { value: 'BiBuildingHouse', icon: <BiBuildingHouse />, label: 'BuildingHouse' },
  { value: 'BiBuildings', icon: <BiBuildings />, label: 'Buildings' },
  { value: 'BiCabinet', icon: <BiCabinet />, label: 'Cabinet' },
  { value: 'BiCalendar', icon: <BiCalendar />, label: 'Calendar' },
  { value: 'BiCalendarEvent', icon: <BiCalendarEvent />, label: 'CalendarEvent' },
  { value: 'BiCartAlt', icon: <BiCartAlt />, label: 'CartAlt' },
  { value: 'BiCategoryAlt', icon: <BiCategoryAlt />, label: 'CategoryAlt' },
  { value: 'BiCategory', icon: <BiCategory />, label: 'Category' },
  { value: 'BiCart', icon: <BiCart />, label: 'Cart' },
  { value: 'BiDollar', icon: <BiDollar />, label: 'Dollar' },
  { value: 'BiDollarCircle', icon: <BiDollarCircle />, label: 'DollarCircle' },
  { value: 'BiEuro', icon: <BiEuro />, label: 'Euro' },
  { value: 'BiNotepad', icon: <BiNotepad />, label: 'Notepad' },
  { value: 'BiNoSignal', icon: <BiNoSignal />, label: 'NoSignal' },
  { value: 'BiObjectsHorizontalLeft', icon: <BiObjectsHorizontalLeft />, label: 'ObjectsHorizontalLeft' },
  { value: 'BiObjectsVerticalBottom', icon: <BiObjectsVerticalBottom />, label: 'ObjectsVerticalBottom' },
  { value: 'BiOutline', icon: <BiOutline />, label: 'Outline' },
  { value: 'BiPackage', icon: <BiPackage />, label: 'Package' },
  { value: 'BiRuble', icon: <BiRuble />, label: 'Ruble' },
  { value: 'BiRupee', icon: <BiRupee />, label: 'Rupee' },
  { value: 'BiSignal2', icon: <BiSignal2 />, label: 'Signal2' },
  { value: 'BiSignal3', icon: <BiSignal3 />, label: 'Signal3' },
  { value: 'BiSignal4', icon: <BiSignal4 />, label: 'Signal4' },
  { value: 'BiSignal5', icon: <BiSignal5 />, label: 'Signal5' },
  { value: 'BiSpa', icon: <BiSpa />, label: 'Spa' },
  { value: 'CiAlignBottom', icon: <CiAlignBottom />, label: 'AlignBottom' },
  { value: 'CiAlignLeft', icon: <CiAlignLeft />, label: 'AlignLeft' },
  { value: 'CiBadgeDollar', icon: <CiBadgeDollar />, label: 'BadgeDollar' },
  { value: 'CiBag1', icon: <CiBag1 />, label: 'Bag1' },
  { value: 'CiForkAndKnife', icon: <CiForkAndKnife />, label: 'ForkAndKnife' },
  { value: 'CiUser', icon: <CiUser />, label: 'User' },
  { value: 'CiStethoscope', icon: <CiStethoscope />, label: 'Stethoscope' },
  { value: 'CiShoppingCart', icon: <CiShoppingCart />, label: 'ShoppingCart' },
  { value: 'CgDesignmodo', icon: <CgDesignmodo />, label: 'Designmodo' },
  { value: 'CgShapeCircle', icon: <CgShapeCircle />, label: 'ShapeCircle' },
  { value: 'FiBarChart2', icon: <FiBarChart2 />, label: 'BarChart2' },
  { value: 'FiMail', icon: <FiMail />, label: 'Mail' },
  { value: 'FiToggleRight', icon: <FiToggleRight />, label: 'ToggleRight' },
  { value: 'FiToggleLeft', icon: <FiToggleLeft />, label: 'ToggleLeft' },
  { value: 'TfiBarChart', icon: <TfiBarChart />, label: 'BarChart' },
  { value: 'TfiLayoutGrid2Thumb', icon: <TfiLayoutGrid2Thumb />, label: 'LayoutGrid2Thumb' },
  { value: 'TfiLayout', icon: <TfiLayout />, label: 'Layout' },
  { value: 'TbBrandGoogleAnalytics', icon: <TbBrandGoogleAnalytics />, label: 'BrandGoogleAnalytics' },
  { value: 'TbCannabis', icon: <TbCannabis />, label: 'Cannabis' },
  { value: 'TbChecks', icon: <TbChecks />, label: 'Checks' },
  { value: 'TbChecklist', icon: <TbChecklist />, label: 'Checklist' },
  { value: 'TbGridDots', icon: <TbGridDots />, label: 'GridDots' },
  { value: 'TbLayout', icon: <TbLayout />, label: 'Layout' },
  { value: 'TbLayoutBoard', icon: <TbLayoutBoard />, label: 'LayoutBoard' },
  { value: 'TbLayoutBoardSplit', icon: <TbLayoutBoardSplit />, label: 'LayoutBoardSplit' },
  { value: 'SiWolfram', icon: <SiWolfram />, label: 'Wolfram' },
  { value: 'SiVowpalwabbit', icon: <SiVowpalwabbit />, label: 'Vowpalwabbit' },
  { value: 'SiTga', icon: <SiTga />, label: 'Tga' },
  { value: 'SiSoundcharts', icon: <SiSoundcharts />, label: 'Soundcharts' },
  { value: 'SiRubysinatra', icon: <SiRubysinatra />, label: 'Rubysinatra' },
  { value: 'SiParsedotly', icon: <SiParsedotly />, label: 'Parsedotly' },
  { value: 'SiJabber', icon: <SiJabber />, label: 'Jabber' },
  { value: 'SiGoogleanalytics', icon: <SiGoogleanalytics />, label: 'GoogleAnalytics' },
  { value: 'SiClyp', icon: <SiClyp />, label: 'Clyp' },
  { value: 'RiAppsLine', icon: <RiAppsLine />, label: 'AppsLine' },
  { value: 'RiBarChartLine', icon: <RiBarChartLine />, label: 'BarChartLine' },
  { value: 'RiBarChartGroupedFill', icon: <RiBarChartGroupedFill />, label: 'BarChartGroupedFill' },
  { value: 'RiBuilding2Line', icon: <RiBuilding2Line />, label: 'Building2Line' },
  { value: 'RiBuildingLine', icon: <RiBuildingLine />, label: 'BuildingLine' },
  { value: 'RiGolfBallLine', icon: <RiGolfBallLine />, label: 'GolfBallLine' },
  { value: 'RiHeart2Line', icon: <RiHeart2Line />, label: 'Heart2Line' },
  { value: 'RiPantoneLine', icon: <RiPantoneLine />, label: 'PantoneLine' },
  { value: 'RiScissors2Line', icon: <RiScissors2Line />, label: 'Scissors2Line' },
  { value: 'RiScales3Line', icon: <RiScales3Line />, label: 'Scales3Line' },
  { value: 'RiToothLine', icon: <RiToothLine />, label: 'ToothLine' },
  { value: 'RxCalendar', icon: <RxCalendar />, label: 'Calendar' },
  { value: 'RxCardStackPlus', icon: <RxCardStackPlus />, label: 'CardStackPlus' },
  { value: 'RxCodesandboxLogo', icon: <RxCodesandboxLogo />, label: 'CodesandboxLogo' },
  { value: 'RxComponent1', icon: <RxComponent1 />, label: 'Component1' },
  { value: 'RxCube', icon: <RxCube />, label: 'Cube' },
  { value: 'PiBedDuotone', icon: <PiBedDuotone />, label: 'BedDuotone' },
  { value: 'PiBeachBallDuotone', icon: <PiBeachBallDuotone />, label: 'BeachBallDuotone' },
  { value: 'PiBell', icon: <PiBell />, label: 'Bell' },
  { value: 'PiBicycleLight', icon: <PiBicycleLight />, label: 'BicycleLight' },
  { value: 'PiBirdLight', icon: <PiBirdLight />, label: 'BirdLight' },
  { value: 'PiBoneLight', icon: <PiBoneLight />, label: 'BoneLight' },
  { value: 'PiCarProfileLight', icon: <PiCarProfileLight />, label: 'CarProfileLight' },
  { value: 'PiCarLight', icon: <PiCarLight />, label: 'CarLight' },
  { value: 'PiCardholder', icon: <PiCardholder />, label: 'Cardholder' },
  { value: 'PiCoffeeLight', icon: <PiCoffeeLight />, label: 'CoffeeLight' },
  { value: 'PiCookingPotLight', icon: <PiCookingPotLight />, label: 'CookingPotLight' },
  { value: 'PiCouchLight', icon: <PiCouchLight />, label: 'CouchLight' },
  { value: 'PiCrown', icon: <PiCrown />, label: 'Crown' },
  { value: 'PiCrownSimpleLight', icon: <PiCrownSimpleLight />, label: 'CrownSimpleLight' },
  { value: 'PiCurrencyGbpLight', icon: <PiCurrencyGbpLight />, label: 'CurrencyGbpLight' },
  { value: 'PiCurrencyJpyLight', icon: <PiCurrencyJpyLight />, label: 'CurrencyJpyLight' },
  { value: 'PiCurrencyKztLight', icon: <PiCurrencyKztLight />, label: 'CurrencyKztLight' },
  { value: 'PiCurrencyNgnLight', icon: <PiCurrencyNgnLight />, label: 'CurrencyNgnLight' },
  { value: 'PiCurrencyRubLight', icon: <PiCurrencyRubLight />, label: 'CurrencyRubLight' },
  { value: 'PiDeskLight', icon: <PiDeskLight />, label: 'DeskLight' },
  { value: 'PiDesktopTowerLight', icon: <PiDesktopTowerLight />, label: 'DesktopTowerLight' },
  { value: 'PiEnvelopeSimpleThin', icon: <PiEnvelopeSimpleThin />, label: 'EnvelopeSimpleThin' },
  { value: 'PiEnvelopeSimpleOpenThin', icon: <PiEnvelopeSimpleOpenThin />, label: 'EnvelopeSimpleOpenThin' },
  { value: 'PiEyesFill', icon: <PiEyesFill />, label: 'EyesFill' },
  { value: 'PiGavelLight', icon: <PiGavelLight />, label: 'GavelLight' },
  { value: 'PiGlobeHemisphereWestDuotone', icon: <PiGlobeHemisphereWestDuotone />, label: 'GlobeHemisphereWestDuotone' },
  { value: 'PiGraduationCapLight', icon: <PiGraduationCapLight />, label: 'GraduationCapLight' },
  { value: 'PiGuitarLight', icon: <PiGuitarLight />, label: 'GuitarLight' },
  { value: 'PiHamburgerLight', icon: <PiHamburgerLight />, label: 'HamburgerLight' },
  { value: 'PiHairDryerLight', icon: <PiHairDryerLight />, label: 'HairDryerLight' },
  { value: 'PiHandPalmLight', icon: <PiHandPalmLight />, label: 'HandPalmLight' },
  { value: 'PiHandbagLight', icon: <PiHandbagLight />, label: 'HandbagLight' },
  { value: 'PiHeadsetLight', icon: <PiHeadsetLight />, label: 'HeadsetLight' },
  { value: 'PiIslandLight', icon: <PiIslandLight />, label: 'IslandLight' },
  { value: 'PiJeepLight', icon: <PiJeepLight />, label: 'JeepLight' },
  { value: 'PiLightbulbFilamentThin', icon: <PiLightbulbFilamentThin />, label: 'LightbulbFilamentThin' },
  { value: 'PiMartiniLight', icon: <PiMartiniLight />, label: 'MartiniLight' },
  { value: 'PiMaskSadLight', icon: <PiMaskSadLight />, label: 'MaskSadLight' },
  { value: 'PiMegaphoneLight', icon: <PiMegaphoneLight />, label: 'MegaphoneLight' },
  { value: 'PiMoneyWavyLight', icon: <PiMoneyWavyLight />, label: 'MoneyWavyLight' },
  { value: 'PiMoneyLight', icon: <PiMoneyLight />, label: 'MoneyLight' },
  { value: 'PiMoped', icon: <PiMoped />, label: 'Moped' },
  { value: 'PiMopedFront', icon: <PiMopedFront />, label: 'MopedFront' },
  { value: 'PiMotorcycleFill', icon: <PiMotorcycleFill />, label: 'MotorcycleFill' },
  { value: 'PiMusicNoteLight', icon: <PiMusicNoteLight />, label: 'MusicNoteLight' },
  { value: 'PiParkLight', icon: <PiParkLight />, label: 'ParkLight' },
  { value: 'PiPizzaLight', icon: <PiPizzaLight />, label: 'PizzaLight' },
  { value: 'PiPlantLight', icon: <PiPlantLight />, label: 'PlantLight' },
  { value: 'PiPrinterLight', icon: <PiPrinterLight />, label: 'PrinterLight' },
  { value: 'PiProjectorScreen', icon: <PiProjectorScreen />, label: 'ProjectorScreen' },
  { value: 'PiProjectorScreenChartLight', icon: <PiProjectorScreenChartLight />, label: 'ProjectorScreenChartLight' },
  { value: 'PiScissorsFill', icon: <PiScissorsFill />, label: 'ScissorsFill' },
  { value: 'PiScooter', icon: <PiScooter />, label: 'Scooter' },
  { value: 'PiScrewdriverLight', icon: <PiScrewdriverLight />, label: 'ScrewdriverLight' },
  { value: 'PiScrollLight', icon: <PiScrollLight />, label: 'ScrollLight' },
  { value: 'PiSecurityCameraLight', icon: <PiSecurityCameraLight />, label: 'SecurityCameraLight' },
  { value: 'PiShippingContainerDuotone', icon: <PiShippingContainerDuotone />, label: 'ShippingContainerDuotone' },
  { value: 'PiShoppingCartSimple', icon: <PiShoppingCartSimple />, label: 'ShoppingCartSimple' },
  { value: 'PiShovelLight', icon: <PiShovelLight />, label: 'ShovelLight' },
  { value: 'PiSketchLogoLight', icon: <PiSketchLogoLight />, label: 'SketchLogoLight' },
  { value: 'PiSpeedometerLight', icon: <PiSpeedometerLight />, label: 'SpeedometerLight' },
  { value: 'PiSphereLight', icon: <PiSphereLight />, label: 'SphereLight' },
  { value: 'PiStudentLight', icon: <PiStudentLight />, label: 'StudentLight' },
  { value: 'PiSyringeLight', icon: <PiSyringeLight />, label: 'SyringeLight' },
  { value: 'PiTennisBallFill', icon: <PiTennisBallFill />, label: 'TennisBallFill' },
  { value: 'PiTentLight', icon: <PiTentLight />, label: 'TentLight' },
  { value: 'PiTestTubeLight', icon: <PiTestTubeLight />, label: 'TestTubeLight' },
  { value: 'PiThermometerLight', icon: <PiThermometerLight />, label: 'ThermometerLight' },
  { value: 'PiTipiFill', icon: <PiTipiFill />, label: 'TipiFill' },
  { value: 'PiTractorLight', icon: <PiTractorLight />, label: 'TractorLight' },
  { value: 'PiTrashLight', icon: <PiTrashLight />, label: 'TrashLight' },
  { value: 'PiTrayLight', icon: <PiTrayLight />, label: 'TrayLight' },
  { value: 'PiTrashSimpleThin', icon: <PiTrashSimpleThin />, label: 'TrashSimpleThin' },
  { value: 'PiTreeEvergreenThin', icon: <PiTreeEvergreenThin />, label: 'TreeEvergreenThin' },
  { value: 'PiTreePalmLight', icon: <PiTreePalmLight />, label: 'TreePalmLight' },
  { value: 'PiTreeLight', icon: <PiTreeLight />, label: 'TreeLight' },
  { value: 'PiTrolley', icon: <PiTrolley />, label: 'Trolley' },
  { value: 'PiTruckLight', icon: <PiTruckLight />, label: 'TruckLight' },
  { value: 'PiTruckTrailerLight', icon: <PiTruckTrailerLight />, label: 'TruckTrailerLight' },
  { value: 'PiUmbrellaThin', icon: <PiUmbrellaThin />, label: 'UmbrellaThin' },
  { value: 'PiVanLight', icon: <PiVanLight />, label: 'VanLight' },
  { value: 'PiVaultLight', icon: <PiVaultLight />, label: 'VaultLight' },
  { value: 'PiWalletLight', icon: <PiWalletLight />, label: 'WalletLight' },
  { value: 'PiWarehouseLight', icon: <PiWarehouseLight />, label: 'WarehouseLight' },
  { value: 'PiWallLight', icon: <PiWallLight />, label: 'WallLight' },
  { value: 'PiWashingMachineLight', icon: <PiWashingMachineLight />, label: 'WashingMachineLight' },
  { value: 'PiWheelchairLight', icon: <PiWheelchairLight />, label: 'WheelchairLight' },
  { value: 'PiWheelchairMotionLight', icon: <PiWheelchairMotionLight />, label: 'WheelchairMotionLight' },
  { value: 'PiWineLight', icon: <PiWineLight />, label: 'WineLight' },
  { value: 'PiWindLight', icon: <PiWindLight />, label: 'WindLight' },
  { value: 'MdBatteryCharging60', icon: <MdBatteryCharging60 />, label: 'BatteryCharging60' },
  { value: 'MdCoffeeMaker', icon: <MdCoffeeMaker />, label: 'CoffeeMaker' },
  { value: 'MdDirectionsBike', icon: <MdDirectionsBike />, label: 'DirectionsBike' },
  { value: 'MdElectricBike', icon: <MdElectricBike />, label: 'ElectricBike' },
  { value: 'MdElectricCar', icon: <MdElectricCar />, label: 'ElectricCar' },
  { value: 'MdElectricScooter', icon: <MdElectricScooter />, label: 'ElectricScooter' },
  { value: 'MdElectricBolt', icon: <MdElectricBolt />, label: 'ElectricBolt' },
  { value: 'MdModeEdit', icon: <MdModeEdit />, label: 'ModeEdit' },
  { value: 'MdOutlineBlender', icon: <MdOutlineBlender />, label: 'OutlineBlender' },
  { value: 'MdOutlineBrush', icon: <MdOutlineBrush />, label: 'OutlineBrush' },
  { value: 'MdOutlineCached', icon: <MdOutlineCached />, label: 'OutlineCached' },
  { value: 'MdOutlineCheck', icon: <MdOutlineCheck />, label: 'OutlineCheck' },
  { value: 'MdOutlineClear', icon: <MdOutlineClear />, label: 'OutlineClear' },
  { value: 'MdOutlineChevronRight', icon: <MdOutlineChevronRight />, label: 'OutlineChevronRight' },
  { value: 'MdOutlineChevronLeft', icon: <MdOutlineChevronLeft />, label: 'OutlineChevronLeft' },
  { value: 'MdOutlineCreate', icon: <MdOutlineCreate />, label: 'OutlineCreate' },
  { value: 'MdOutlineGrass', icon: <MdOutlineGrass />, label: 'OutlineGrass' },
  { value: 'MdOutlineTwoWheeler', icon: <MdOutlineTwoWheeler />, label: 'OutlineTwoWheeler' },
  { value: 'MdQuestionMark', icon: <MdQuestionMark />, label: 'QuestionMark' },
  { value: 'MdSailing', icon: <MdSailing />, label: 'Sailing' },
  { value: 'MdSatelliteAlt', icon: <MdSatelliteAlt />, label: 'SatelliteAlt' },
  { value: 'MdSignalWifiConnectedNoInternet0', icon: <MdSignalWifiConnectedNoInternet0 />, label: 'SignalWifiConnectedNoInternet0' },
  { value: 'MdSignalWifiStatusbarConnectedNoInternet', icon: <MdSignalWifiStatusbarConnectedNoInternet />, label: 'SignalWifiStatusbarConnectedNoInternet' },
  { value: 'MdSignalWifiStatusbarNotConnected', icon: <MdSignalWifiStatusbarNotConnected />, label: 'SignalWifiStatusbarNotConnected' },
  { value: 'MdSignalWifi3Bar', icon: <MdSignalWifi3Bar />, label: 'SignalWifi3Bar' },
  { value: 'MdSwitchAccessShortcutAdd', icon: <MdSwitchAccessShortcutAdd />, label: 'SwitchAccessShortcutAdd' },
  { value: 'MdSync', icon: <MdSync />, label: 'Sync' },
  { value: 'MdSyncDisabled', icon: <MdSyncDisabled />, label: 'SyncDisabled' },
  { value: 'MdSyncLock', icon: <MdSyncLock />, label: 'SyncLock' },
  { value: 'MdSyncProblem', icon: <MdSyncProblem />, label: 'SyncProblem' },
  { value: 'MdTerrain', icon: <MdTerrain />, label: 'Terrain' },
  { value: 'MdThumbUpOffAlt', icon: <MdThumbUpOffAlt />, label: 'ThumbUpOffAlt' },
  { value: 'MdThumbDownOffAlt', icon: <MdThumbDownOffAlt />, label: 'ThumbDownOffAlt' },
  { value: 'MdTimeToLeave', icon: <MdTimeToLeave />, label: 'TimeToLeave' },
  { value: 'MdWindPower', icon: <MdWindPower />, label: 'WindPower' },
  { value: 'LuAirVent', icon: <LuAirVent />, label: 'AirVent' },
  { value: 'LuCake', icon: <LuCake />, label: 'Cake' },
  { value: 'LuJapaneseYen', icon: <LuJapaneseYen />, label: 'JapaneseYen' },
  { value: 'LuPen', icon: <LuPen />, label: 'Pen' },
  { value: 'LuPencil', icon: <LuPencil />, label: 'Pencil' },
  { value: 'ImPencil2', icon: <ImPencil2 />, label: 'Pencil2' },
  { value: 'HiListBullet', icon: <HiListBullet />, label: 'ListBullet' },
  { value: 'HiLockClosed', icon: <HiLockClosed />, label: 'LockClosed' },
  { value: 'HiLockOpen', icon: <HiLockOpen />, label: 'LockOpen' },
  { value: 'HiMagnifyingGlass', icon: <HiMagnifyingGlass />, label: 'MagnifyingGlass' },  
  { value: 'HiOutlineArrowDownTray', icon: <HiOutlineArrowDownTray />, label: 'OutlineArrowDownTray' },
  { value: 'HiOutlinePresentationChartBar', icon: <HiOutlinePresentationChartBar />, label: 'OutlinePresentationChartBar' },
  { value: 'ImBarcode', icon: <ImBarcode />, label: 'Barcode' },
  { value: 'ImMan', icon: <ImMan />, label: 'Man' },
  { value: 'ImManWoman', icon: <ImManWoman />, label: 'ManWoman' },
  { value: 'LiaAmbulanceSolid', icon: <LiaAmbulanceSolid />, label: 'AmbulanceSolid' },
  { value: 'LiaCashRegisterSolid', icon: <LiaCashRegisterSolid />, label: 'CashRegisterSolid' },
  { value: 'LiaCentos', icon: <LiaCentos />, label: 'Centos' },
  { value: 'LiaGlobeAmericasSolid', icon: <LiaGlobeAmericasSolid />, label: 'GlobeAmericasSolid' },
  { value: 'LiaMoneyCheckAltSolid', icon: <LiaMoneyCheckAltSolid />, label: 'MoneyCheckAltSolid' },
  { value: 'LiaPenAltSolid', icon: <LiaPenAltSolid />, label: 'PenAltSolid' },
  { value: 'LiaSignatureSolid', icon: <LiaSignatureSolid />, label: 'SignatureSolid' },
  { value: 'LiaSpiderSolid', icon: <LiaSpiderSolid />, label: 'SpiderSolid' },
  { value: 'LiaTheaterMasksSolid', icon: <LiaTheaterMasksSolid />, label: 'TheaterMasksSolid' },
  { value: 'LiaWhmcs', icon: <LiaWhmcs />, label: 'Whmcs' },
  { value: 'LiaYoutube', icon: <LiaYoutube />, label: 'Youtube' },
  { value: 'GoArrowBoth', icon: <GoArrowBoth />, label: 'ArrowBoth' },
  { value: 'GoArrowSwitch', icon: <GoArrowSwitch />, label: 'ArrowSwitch' },
  { value: 'GoArrowDown', icon: <GoArrowDown />, label: 'ArrowDown' },
  { value: 'GoArrowDownLeft', icon: <GoArrowDownLeft />, label: 'ArrowDownLeft' },
  { value: 'GoArrowDownRight', icon: <GoArrowDownRight />, label: 'ArrowDownRight' },
  { value: 'GoArrowLeft', icon: <GoArrowLeft />, label: 'ArrowLeft' },
  { value: 'GoArrowRight', icon: <GoArrowRight />, label: 'ArrowRight' },
  { value: 'GoArrowUp', icon: <GoArrowUp />, label: 'ArrowUp' },
  { value: 'GoArrowUpLeft', icon: <GoArrowUpLeft />, label: 'ArrowUpLeft' },
  { value: 'GoArrowUpRight', icon: <GoArrowUpRight />, label: 'ArrowUpRight' },
  { value: 'GoBeaker', icon: <GoBeaker />, label: 'Beaker' },
  { value: 'GoBook', icon: <GoBook />, label: 'Book' },
  { value: 'GoBookmark', icon: <GoBookmark />, label: 'Bookmark' },
  { value: 'GoBellSlash', icon: <GoBellSlash />, label: 'BellSlash' },
  { value: 'GoBell', icon: <GoBell />, label: 'Bell' },
  { value: 'GoBookmarkSlash', icon: <GoBookmarkSlash />, label: 'BookmarkSlash' },
  { value: 'GoCheckCircle', icon: <GoCheckCircle />, label: 'CheckCircle' },
  { value: 'GoCircle', icon: <GoCircle />, label: 'Circle' },
  { value: 'GoClock', icon: <GoClock />, label: 'Clock' },
  { value: 'GoCodeOfConduct', icon: <GoCodeOfConduct />, label: 'CodeOfConduct' },
  { value: 'GoCommentDiscussion', icon: <GoCommentDiscussion />, label: 'CommentDiscussion' },
  { value: 'GoPerson', icon: <GoPerson />, label: 'Person' },
  { value: 'GoThumbsup', icon: <GoThumbsup />, label: 'Thumbsup' },
  { value: 'GoThumbsdown', icon: <GoThumbsdown />, label: 'Thumbsdown' },
  { value: 'GoSun', icon: <GoSun />, label: 'Sun' },
  { value: 'GoTrophy', icon: <GoTrophy />, label: 'Trophy' },
  { value: 'GoSquirrel', icon: <GoSquirrel />, label: 'Squirrel' },
  { value: 'GoShieldCheck', icon: <GoShieldCheck />, label: 'ShieldCheck' },
  { value: 'GoShieldLock', icon: <GoShieldLock />, label: 'ShieldLock' },
  { value: 'GoCrossReference', icon: <GoCrossReference />, label: 'CrossReference' },
  { value: 'GoFileMedia', icon: <GoFileMedia />, label: 'FileMedia' },
  { value: 'GoKey', icon: <GoKey />, label: 'Key' },
  { value: 'GoLaw', icon: <GoLaw />, label: 'Law' },
  { value: 'GoLocation', icon: <GoLocation />, label: 'Location' },
  { value: 'GoMilestone', icon: <GoMilestone />, label: 'Milestone' },
  { value: 'CiCoffeeCup', icon: <CiCoffeeCup />, label: 'CoffeeCup' },
  { value: 'CiSaveUp2', icon: <CiSaveUp2 />, label: 'SaveUp2' },
  { value: 'IoCloudUploadOutline', icon: <IoCloudUploadOutline />, label: 'CloudUploadOutline' },
  { value: 'GiSuperMushroom', icon: <GiSuperMushroom />, label: 'SuperMushroom' },
  { value: 'BsUpcScan', icon: <BsUpcScan />, label: 'UpcScan' },
  { value: 'BsJournalArrowUp', icon: <BsJournalArrowUp />, label: 'JournalArrowUp' },
  { value: 'BsUpc', icon: <BsUpc />, label: 'Upc' },
  { value: 'BiVideoRecording', icon: <BiVideoRecording />, label: 'VideoRecording' },
  { value: 'RiRecordMailLine', icon: <RiRecordMailLine />, label: 'RecordMailLine' },
  { value: 'IoMdRecording', icon: <IoMdRecording />, label: 'MdRecording' },
  { value: 'IoIosPhonePortrait', icon: <IoIosPhonePortrait />, label: 'IosPhonePortrait' },
  { value: 'IoIosPhoneLandscape', icon: <IoIosPhoneLandscape />, label: 'IosPhoneLandscape' },
  { value: 'IoPhonePortraitOutline', icon: <IoPhonePortraitOutline />, label: 'PhonePortraitOutline' },
  { value: 'IoPhoneLandscapeOutline', icon: <IoPhoneLandscapeOutline />, label: 'PhoneLandscapeOutline' },
  { value: 'CiMicrophoneOn', icon: <CiMicrophoneOn />, label: 'MicrophoneOn' },
  { value: 'CiMicrophoneOff', icon: <CiMicrophoneOff />, label: 'MicrophoneOff' },
  { value: 'FaHeadphonesSimple', icon: <FaHeadphonesSimple />, label: 'HeadphonesSimple' },
  { value: 'FiPhone', icon: <FiPhone />, label: 'Phone' },
  { value: 'GiMicrophone', icon: <GiMicrophone />, label: 'Microphone' },
  { value: 'TfiFaceSad', icon: <TfiFaceSad />, label: 'FaceSad' },
  { value: 'TfiFaceSmile', icon: <TfiFaceSmile />, label: 'FaceSmile' },
  { value: 'GrFacebookOption', icon: <GrFacebookOption />, label: 'FacebookOption' },
  { value: 'MdCurrencyFranc', icon: <MdCurrencyFranc />, label: 'CurrencyFranc' },
  { value: 'TbCurrencyDinar', icon: <TbCurrencyDinar />, label: 'CurrencyDinar' },
  { value: 'TbCurrencyCent', icon: <TbCurrencyCent />, label: 'CurrencyCent' },
  { value: 'IoCarSport', icon: <IoCarSport />, label: 'CarSport' },
  { value: 'PiFadersHorizontalLight', icon: <PiFadersHorizontalLight />, label: 'FadersHorizontalLight' },
  { value: 'PiFadersThin', icon: <PiFadersThin />, label: 'FadersThin' },
  { value: 'PiFileRsLight', icon: <PiFileRsLight />, label: 'FileRsLight' },
  { value: 'LiaSpinnerSolid', icon: <LiaSpinnerSolid />, label: 'SpinnerSolid' },
  { value: 'CiBank', icon: <CiBank />, label: 'Bank' },
  { value: 'GiBank', icon: <GiBank />, label: 'Bank' },
  { value: 'BsBank2', icon: <BsBank2 />, label: 'Bank2' },
  { value: 'PiBank', icon: <PiBank />, label: 'Bank' },
  { value: 'GiWingCloak', icon: <GiWingCloak />, label: 'WingCloak' },
];

export default icons;
