import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const EmailBox = ({ label, value, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="email" // Input type for email
    value={value}
    onChange={onChanged}
    InputLabelProps={{ shrink: true }} // Ensures the label stays visible above the input
    fullWidth
    className="Add-Input"
  />
);

export default EmailBox;
