import React from 'react';
import { TextField } from '@mui/material';
import './Input.css';

const ColorPicker = ({ label, value, onChanged }) => (
  <TextField
    label={label} // Display the label
    type="color" // Input type for color picker
    value={value}
    onChange={onChanged}
    InputLabelProps={{ shrink: true }} // Ensures the label stays visible
    fullWidth
    className="Add-Input"
  />
);

export default ColorPicker;
