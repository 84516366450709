import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill Snow theme CSS
import { FormLabel } from '@mui/material'; // Import MUI FormLabel for consistency
import './Input.css';

const RichTextEditor = ({ label, value, onChanged }) => (
  <div>
    <FormLabel>{label}</FormLabel> {/* Display the label */}
    <ReactQuill
      value={value}
      onChange={onChanged}
      theme="snow" // Theme for rich text editor
    />
  </div>
);

export default RichTextEditor;
