import './x.css';
import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment, Popover, Button } from "@mui/material";
import { GoSearch } from "react-icons/go";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { FaChevronDown } from "react-icons/fa";

import { BASE_URL } from "../../url/base";
import { getIconComponent } from './getIconComponent'; // Import your icon component

const Lookup = ({ label, value, onChanged, relatedTo, idField, relatedToColumns }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [lookupData, setLookupData] = useState([]);
  
  useEffect(() => {
    if (value) {
      setSelectedIcon({
        label: String(value || "No Label"), // Ensure label is a string
        //value: value, // Use the value from `id_field` or fallback
        //icon: value.name, // Include the resolved icon
      });
      
    }
  }, [value, label, idField, relatedToColumns]);

  useEffect(() => {
    if (!relatedTo) return;
  
    const fetchLookupData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found in localStorage");
  
        const response = await fetch(`${BASE_URL}srecord/${relatedTo}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
        const result = await response.json();
  
        if (result?.data && Array.isArray(result.data)) {
          // Find the field with "is_name: true"
          const isNameField = result.columns.find((col) => col.is_name);
  
          if (!isNameField) {
            console.warn("No field with 'is_name: true' found");
            return;
          }

          // Log the field being used for label

          // Resolve the icons asynchronously
          const mappedData = await Promise.all(
            result.data.map(async (item) => {
              // Use `idField` if provided, otherwise fall back to "id"
              const labelField = idField || "id"; 
  
              // Get the label for the item using the field with "is_name: true"
              const label = item[isNameField.name];  // Dynamically get the field with `is_name: true`
  
              // Log the item and the selected label field for debugging

  
              // Resolve icon asynchronously before returning data
              const icon = item.icon ? await getIconComponent(item.value) : null;
  
              return {
                label: String(label || "No Label"), // Ensure label is a string
                value: item[idField] || item[labelField] || "", // Use the value from `id_field` or fallback
                icon, // Include the resolved icon
              };
            })
          );
  
          // Set the data with resolved icons
          setLookupData(mappedData); // Set the mapped data in state
        } else {
          console.warn("No data returned for", relatedTo);
        }
      } catch (error) {
        console.error("Error fetching lookup data:", error);
      }
    };
  
    fetchLookupData();
  }, [relatedTo, idField]);

  const filteredIcons = lookupData.filter(
    (option) =>
      option.label &&
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleSelectChange = (option) => {
    setSelectedIcon(option);
    if (typeof onChanged === "function") {
      onChanged(option.value); // Send the value (idField)
    }
    setAnchorEl(null);
  };
  
  const handleInputClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm("");
  };

  const handleClearSelection = () => {
    setSelectedIcon(null);
    if (typeof onChanged === "function") onChanged(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="lookup-container">
      <div
        className={`lookup-input ${selectedIcon ? "filled" : ""}`}
        onClick={handleInputClick}
        style={{ cursor: "pointer" }}
      >
        <div className="icon-input">
          {selectedIcon ? (
            <InputAdornment position="start">
              {selectedIcon.label} {/* Render selected icon label once here */}
            </InputAdornment>
          ) : (
            <span className="lookup-label">{label}</span> // Display label when no icon is selected
          )}
        </div>
        <InputAdornment position="end">
          {selectedIcon ? (
            <IconButton
              onClick={handleClearSelection}
              aria-label="clear selection"
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <FaChevronDown style={{ cursor: "pointer" }} />
          )}
        </InputAdornment>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ zIndex: 9999 }}
      >
        <div className="Dp-container">
          <div className="LP-search-container">
            <GoSearch className="LP-search-icon" />
            <input
              autoFocus
              className="LP-search-input"
              placeholder="Search"
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="icon-dropdown">
            {filteredIcons.length > 0 ? (
              filteredIcons.map((option, index) => (
                <div
                  key={index}
                  className="icon-option"
                  onClick={() => handleSelectChange(option)}
                >
                  <span style={{ marginRight: "10px" }}>{option.icon}</span>
                  {option.label}
                </div>
              ))
            ) : (
              <div className="icon-option disabled">No records found</div>
            )}
          </div>

          <Button
            className="cancel-Sel-btn"
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleClearSelection}
            style={{ marginTop: "16px" }}
          >
            Cancel Selection
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default Lookup;
